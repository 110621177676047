import React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import helpers from '../../lib/helpers';

const LocationList = ({ campuses, locale, onCampusClick, className }) => {
  return (
    <div
      className={classNames(
        className,
        css`
          position: absolute;
          left: 5%;
          top: 50%;
          background-color: rgba(0, 0, 0, 0.8);
          border: 1px solid #71bfe7;
          transform: translateY(-50%);
          width: 316px;
          font-size: 24px;

          h3 {
            font-size: inherit;
            font-weight: 400;
            text-transform: uppercase;
            text-align: center;
            background-color: #71bfe7;
            padding: 1rem 0;
            color: #112c51;
          }

          ul {
            padding: 0 1.75rem;
            max-height: 520px;
            overflow: auto;
          }

          li {
            cursor: pointer;
            padding: 1em 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            &:last-child {
              border-bottom: 0;
              padding-bottom: 0;
            }
          }
        `
      )}
    >
      <h3>Locations</h3>
      <ul className="list-unstyled">
        {campuses.map((c, j) => (
          <li
            key={j}
            onClick={(e) => {
              e.preventDefault();
              onCampusClick(c);
            }}
          >
            {helpers.translateField(c, 'name', locale)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LocationList;
