import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { css } from '@emotion/css';
import mediaQueries from '../../lib/mediaQueries';
import debounce from 'lodash.debounce';
import Map from '../shared/Map';
import {
  Popup,
  NavigationControl,
  FlyToInterpolator,
  Layer,
  Source,
  GeolocateControl,
} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Filters from './Filters';
import ChurchPin from '../shared/ChurchPin';
import GroupDetails from '../shared/Popups/GroupDetails';
import LocationList from '../shared/LocationList';
import {
  clusterLayer,
  clusterCountLayer,
  unclusteredPointLayerIcon,
} from '../shared/layersStyles';

const MAPBOX_TOKEN = process.env.MAPBOX_TOKEN;

const OnlineCampusMap = ({ campuses, groups, locale }) => {
  let location = useLocation();
  const mapRef = useRef();
  const [filters, setFilters] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const SLK = campuses.filter((c) => c.name_en === 'Southlake Campus')[0];
  const [viewport, setViewport] = useState({
    longitude: SLK.lng,
    latitude: SLK.lat,
    zoom: 10,
    maxzoom: 14,
    transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
    transitionDuration: 'auto',
  });

  useEffect(() => {
    // setViewport lat and lon for selected gathering
    // setPopupContent for selected gathering
    console.log(location);
  }, [location]);

  const groupsData = useMemo(() => {
    const filteredGroups = filters
      ? groups.filter((g) => g[filters.key] === filters.value)
      : groups;

    return {
      type: 'FeatureCollection',
      features: filteredGroups.map((g) => ({
        type: 'Feature',
        properties: {
          data: { ...g },
        },
        geometry: {
          type: 'Point',
          coordinates: [g?.longitude, g?.latitude],
        },
      })),
    };
  }, [filters, groups]);

  const zoomToCampus = (campus) => {
    setViewport({
      ...viewport,
      longitude: campus.lng,
      latitude: campus.lat,
      transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
      transitionDuration: 'auto',
      zoom: 14,
    });
  };

  const onMapClick = (event) => {
    const feature = event?.features?.[0];
    if (!feature) return event;

    const clusterId = feature?.properties?.cluster_id || false;
    const mapboxSource = mapRef.current.getMap().getSource('groups');

    if (!clusterId) {
      // we've clicked on an single group
      setViewport({
        ...viewport,
        transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
        transitionDuration: 'auto',
        longitude: feature.geometry.coordinates[0],
        latitude: feature.geometry.coordinates[1],
      });

      setTimeout(() => {
        setPopupContent(JSON.parse(feature?.properties?.data));
      }, 500);
    } else {
      // We've clicked on a cluster
      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return console.log(err);

        setViewport({
          ...viewport,
          longitude: feature.geometry.coordinates[0],
          latitude: feature.geometry.coordinates[1],
          transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
          transitionDuration: 'auto',
          zoom,
        });
      });
    }
  };

  const onMapLoad = (event) => {
    const map = event.target;
    map.loadImage('/assets/icons/group-icon.png', (error, image) => {
      if (error) throw error;
      return map.addImage('groupIcon', image);
    });
  };

  const campusPins = React.useMemo(
    () =>
      campuses
        .filter((c) => c.slug !== 'online')
        .map((d, i) => (
          <ChurchPin
            color="#aeaeae"
            key={i}
            data={d}
            locale={locale}
            onClick={() => zoomToCampus(c)}
          />
        )),
    [campuses]
  );

  return (
    <>
      <Map
        viewport={viewport}
        setViewport={setViewport}
        interactiveLayerIds={[clusterLayer.id, unclusteredPointLayerIcon.id]}
        ref={mapRef}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
        {campusPins}

        <Source
          id="groups"
          type="geojson"
          data={groupsData}
          cluster={true}
          maxzoom={15}
          clusterRadius={50}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayerIcon} />
        </Source>

        {popupContent && (
          <Popup
            anchor="top"
            longitude={popupContent.longitude}
            latitude={popupContent.latitude}
            closeOnClick={true}
            offsetTop={35}
            onClose={setPopupContent}
            className={css`
              color: white;

              .mapboxgl-popup-content {
                border-radius: 0;
                border-bottom-left-radius: 1rem;
                border-bottom-right-radius: 1rem;
                padding: 2rem;
                box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.2);
                background-color: rgba(10, 10, 10, 0.95);
                color: white;
              }

              .mapboxgl-popup-close-button {
                color: inherit;
                margin-top: 10px;
                margin-right: 10px;
              }

              .mapboxgl-popup-tip {
                border-bottom-color: rgba(10, 10, 10, 0.9) !important;
              }
            `}
          >
            <GroupDetails group={popupContent} locale={locale} />
          </Popup>
        )}

        <NavigationControl
          showCompass={false}
          className={css`
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            max-width: 29px;
          `}
        />
      </Map>
      <LocationList
        className={css`
          display: none;

          ${mediaQueries.lg} {
            display: block;
          }
        `}
        campuses={campuses.filter((c) => c.slug !== 'online')}
        locale={locale}
        onCampusClick={zoomToCampus}
      />
      <GeolocateControl
        className={css`
          display: none;
          width: 29px;
          height: 29px;
          position: absolute;
          right: 1rem;
          bottom: calc(1rem + 65px);

          ${mediaQueries.lg} {
            display: block;
          }
        `}
        positionOptions={{
          enableHighAccuracy: false,
        }}
        onGeolocate={(e) => {
          console.log(e);
        }}
        trackUserLocation={true}
        auto
      />
      <Filters
        onChange={setFilters}
        onSearch={(search) => debounce(doSearch(search), 500)}
        campuses={campuses}
        groupTypes={groups.reduce((acc, cur) => {
          if (acc.indexOf(cur.type) === -1) {
            acc.push(cur.type);
          }
          return acc;
        }, [])}
        locale={locale}
        mapRef={mapRef}
        token={MAPBOX_TOKEN}
        setViewport={setViewport}
        className={css`
          display: none !important;

          ${mediaQueries.lg} {
            display: flex !important;
            bottom: 1rem;
            right: 65px;
          }
        `}
      />
    </>
  );
};

export default OnlineCampusMap;
