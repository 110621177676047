import React from 'react'
import {
  createYouTubeURL,
  formatDateFromAPI,
  formatVideoDescription,
  formatVideoTitle,
} from '../../../yt_live_player/util/youtube'
import { dateToInteger } from "../../../yt_live_player/util/date"

function VideoCard({
  video,
  playlistID,
  locale = "en",
  structuredData = null,
}) {
  const { videoId, publishDate, videoTitle, videoDescription, videoThumbnail } =
    React.useMemo(() => {
      return {
        videoId:
          video?.snippet?.resourceId?.videoId ||
          video?.id?.videoId ||
          video?.id,
        publishDate:
          video?.contentDetails?.videoPublishedAt ||
          video?.snippet?.publishedAt,
        videoTitle: formatVideoTitle(video.snippet.title),
        videoDescription: formatVideoDescription(video.snippet.description),
        videoThumbnail:
          video?.snippet?.thumbnails?.high?.url ||
          video?.snippet?.thumbnails?.medium?.url,
      }
    }, [video])
  return (
    <div
      className="sermon-card-component text-inherit"
      itemProp="hasPart"
      itemScope
      itemType="https://schema.org/Episode"
    >
      <meta itemProp="datePublished" content={publishDate} />
      <meta itemProp="url" content={window.location.href} />
      <meta property="sermon_seo_description" content={videoDescription} />
      {structuredData && structuredData?.position && structuredData?.itemCount && (
        <div className="d-none">
          <span itemProp="position">{structuredData.position}</span> of{" "}
          {structuredData?.itemCount}
        </div>
      )}
      {/* TODO: Once we can support the author we need to add this */}
      {/* <author itemprop="author">Robert Morris</author> */}
      <a
        data-turbolinks="false"
        className="sermon-card"
        href={createYouTubeURL({
          type: "video",
          id: videoId,
          slug: videoTitle.split("|")?.[0]?.trim(),
          params: playlistID ? `?playlist=${playlistID}` : "",
          locale,
        })}
      >
        <img
          src={videoThumbnail}
          alt={videoTitle}
          className="youtube-thumbnail w-full h-auto"
        />
        <h2
          className="h4 text-balance"
          itemProp="name"
          data-sermon-seo-title={videoTitle}
          dangerouslySetInnerHTML={{
            __html: videoTitle,
          }}
        />
        <div className="date" data-timestamp={dateToInteger(publishDate)}>
          {formatDateFromAPI(publishDate, locale)}
        </div>
        <p className="description mt-1 mb-4" itemProp="description">
          {videoDescription}
        </p>
      </a>
    </div>
  )
}

export default VideoCard
