import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function LoaderCard() {
  return (
    <div>
      <Skeleton
        height={200}
        className="w-full"
        style={{
          height: 'auto',
          aspectRatio: '16/9',
        }}
      />
      <div className="mt-2">
        <Skeleton height={23} className="w-full" />
      </div>
      <Skeleton height={14} width={100} className="mb-2" />
      <Skeleton height={10} className="w-full" count={2} />
      <Skeleton height={12} width={150} className="w-full" />
    </div>
  )
}

export default LoaderCard
