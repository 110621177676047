import React from 'react';
import ReactMapGL, { GeolocateControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const VanillaMap = function () {
  const [viewport, setViewport] = React.useState({
    longitude: -122.45,
    latitude: 37.78,
    zoom: 14,
  });
  return (
    <ReactMapGL
      {...viewport}
      width="100%"
      height="50vw"
      onViewportChange={setViewport}
      mapboxApiAccessToken={process.env.MAPBOX_TOKEN}
    >
      <GeolocateControl
        positionOptions={{ enableHighAccuracy: false }}
        onViewportChange={setViewport}
        trackUserLocation={false}
      />
    </ReactMapGL>
  );
};

export default VanillaMap;
