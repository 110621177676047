import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-map-gl';
import classNames from 'classnames';
import { css } from '@emotion/css';

const icons = {
  gathering: {
    size: 40,
    image: '/assets/icons/group-icon.png',
  },
  campus: {
    size: 50,
    image: '/assets/icons/campus-map-icon.png',
  },
};

function Pins({
  data,
  onClick,
  viewport,
  popupVisible,
  icon,
  showLabel,
  labelField,
}) {
  return data.map((d, index) => (
    <Marker
      key={`marker-${index}`}
      longitude={d.longitude}
      latitude={d.latitude}
      className={css`
        &:hover {
          z-index: 100000;
        }
      `}
    >
      <div
        className={css`
          position: relative;
          transform: translate(-50%, -50%);

          &:hover {
            z-index: 100000;

            .hover {
              opacity: ${!popupVisible || popupVisible?.id != d.id ? '1' : '0'};
            }
          }

          .hover {
            opacity: ${!popupVisible && viewport.zoom > 6 ? '1' : '0'};
          }
        `}
      >
        <img
          src={icons[icon].image}
          height={icons[icon].size}
          onClick={() => onClick(d)}
          className={css`
            margin-bottom: 0;
            cursor: pointer;
            width: auto;
          `}
        />
        {showLabel && (
          <span
            className={classNames(
              'hover',
              css`
                background-color: white;
                position: absolute;
                bottom: 0;
                text-align: center;
                left: 50%;
                transform: translate(-50%, 125%);
                white-space: nowrap;
                font-size: 14px;
                padding: 3px 9px;
                border-radius: 5px;
                pointer-events: none;
                font-weight: 600;
                color: var(--gw-gray);
              `
            )}
          >
            {d[labelField]}
          </span>
        )}
      </div>
    </Marker>
  ));
}

Pins.defaultProps = {
  data: [],
  onClick: () => {},
  showLabel: true,
  labelField: 'name',
};

Pins.propTypes = {
  data: PropTypes.array,
  onClick: PropTypes.func,
  viewport: PropTypes.object,
  popupVisible: PropTypes.bool,
  icon: PropTypes.string,
  showLabel: PropTypes.boolean,
  labelField: PropTypes.string,
};

export default React.memo(Pins);
