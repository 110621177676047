import React from "react";

function StructuredDataPublisher() {
  return (
    <div
      className="d-none"
      itemProp="publisher"
      itemScope
      itemType="https://schema.org/Organization"
    >
      <meta itemProp="name" content="Gateway Chruch" />
      <link itemProp="url" href="https://gatewaypeople.com/" />
      <div itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
        <link
          itemProp="url"
          href="https://cf1.gatewaypeople.com/assets/gateway_logo-95e98948f14f037dd1543afdbfdc97c0a2bd551476f83429131163083fd484ed.svg"
        />
      </div>
    </div>
  );
}

export default StructuredDataPublisher;
