const slugify = require("slugify")

export default {
  translateField: (theObj, field, locale, fallback = false) => {
    const fieldName = `${field}_${locale}`

    // If the translated version of the field exists in theObj return it
    if (fieldName in theObj && theObj[fieldName]) {
      return theObj[fieldName]
    }

    // Otherwise if we're falling back return the _en version of the field
    if (fallback && `${field}_en` in theObj && theObj[`${field}_en`]) {
      return theObj[`${field}_en`]
    }

    // If the locae field doesn't exist try to return the field name without locale
    if (field in theObj && theObj[field]) {
      return theObj[field]
    }

    return field
  },

  stripTags: (orig) => {
    return orig.replace(/(<([^>]+)>)/gi, "")
  },

  createPCOLink: (title, path = "groups/") => {
    const slug = slugify(title, {
      remove: /[*+~.()'"!:@,]/g,
      lower: true,
    })

    return `https://gatewaypeople.churchcenter.com/${path}/${slug}`
  },

  // Prepares the source data for use as a mapbox source layer
  prepareSourceData: (data, latKey, lonKey) => {
    return {
      type: "FeatureCollection",
      features: data.map((d) => {
        return {
          type: "Feature",
          properties: {
            title: d.name || d.name_en || d.name_es,
            data: d,
          },
          geometry: {
            type: "Point",
            coordinates: [d[lonKey], d[latKey]],
          },
        }
      }),
    }
  },

  standardizeLatLng: (data) => {
    data.latitude = "lat" in data ? data.lat : data.latitude
    data.longitude = "lng" in data ? data.lng : data.longitude
    return data
  },

  validEmail: (mail) => {
    // eslint-disable-next-line no-useless-escape
    return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
      mail
    )
  },
}

export const smoothScroll = (target) => {
  const header = document.querySelector("header.primary-nav")
  const y =
    target.getBoundingClientRect().top -
    header.getBoundingClientRect().height +
    window.scrollY
  window.scroll({
    top: y,
    behavior: "smooth",
  })
}
