import React from "react"
import { useContent } from "../context/ContentProvider"

function Dates() {
  const { content } = useContent()

  return (
    <section className="christmas-2023__dates px-4 pt-4 pt-lg-6 pb-lg-2">
      <h2 className="text-center text-gold font-chronicle mb-4">
        {content?.dates?.title_html}
      </h2>
      <div className="christmas-2023__dates__grid d-lg-flex mx-auto">
        <div className="christmas-2023__dates__grid__col">
          {content?.dates?.dates?.slice(0, 3).map((event, i) => (
            <DateGridEvent key={i} event={event} />
          ))}
        </div>
        <div className="christmas-2023__dates__grid__col">
          {content?.dates?.dates
            ?.slice(3, content?.dates?.dates.length)
            .map((event, i) => (
              <DateGridEvent key={i} event={event} />
            ))}
        </div>
      </div>
    </section>
  )
}

function DateGridEvent({ event }) {
  const handleClick = (e) => {
    e.preventDefault()
    return document
      .getElementById(event?.section_id)
      .scrollIntoView({ behavior: "smooth" })
  }

  return (
    <a
      href={`#${event.section_id}`}
      className="christmas-2023__dates__grid__item d-block mb-4 pl-3 pr-1 py-2 cursor-pointer"
      onClick={handleClick}
    >
      <time className="d-block mb-1">{event?.date}</time>
      <div
        className="christmas-2023__dates__grid__item__title mb-1"
        dangerouslySetInnerHTML={{
          __html: event?.title_html,
        }}
      />
      <div className="christmas-2023__dates__grid__item__readMore text-decoration-none">
        {event?.read_more}
      </div>
    </a>
  )
}

export default Dates
