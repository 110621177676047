import React from "react"
import {
  formatDateFromAPI,
  formatVideoDescription,
  getPlaylistItems,
  getVideo,
} from "../../yt_live_player/util/youtube"
import classNames from "classnames"
import StructuredDataPublisher from "../../shared/StructuredData/StructuredDataPublisher"
import VideoPlayer from "../components/Video/VideoPlayer"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

function Kids({ locale, content, youTubeIds }) {
  const settings = JSON.parse(youTubeIds)
  const [videos, setVideos] = React.useState(null)
  const [activeVideo, setActiveVideo] = React.useState(false)

  React.useEffect(() => {
    const fetchVideos = async () => {
      // Fetch baby video details - 9-2hqPDDu3w, the babies video is fixed/static
      const babyVideo = await getVideo("9-2hqPDDu3w")
      setVideos([babyVideo?.items?.[0]])

      // Grab the most recent kids messages from the playlist
      const mostRecentKidsVideos = await getPlaylistItems(
        settings?.data?.attributes?.playlist?.kids,
        100
      )

      if (mostRecentKidsVideos?.items?.length > 0) {
        const parseDate = (dateString) => new Date(dateString)
        // Sort the playlist items by date so we make sure the most recent is first.
        // Videos that are added to a playlist aren't sorted by date by default
        // but rather whatever is specified by our team.
        let mostRecentVideo =
          mostRecentKidsVideos?.items?.sort((a, b) => {
            return (
              parseDate(b.snippet.publishedAt) -
              parseDate(a.snippet.publishedAt)
            )
          })?.[0] || null

        if (!mostRecentVideo) return null

        // Fetch the video resource
        mostRecentVideo = await getVideo(
          mostRecentVideo?.contentDetails?.videoId
        )

        setVideos([babyVideo?.items?.[0], mostRecentVideo?.items?.[0]])
      }
    }

    fetchVideos()
  }, [])

  return (
    <div id="top">
      <header className="featured-image gwp-flex-container">
        {!activeVideo && (
          <picture className="hero--container">
            <source
              media="(min-width: 992px)"
              srcSet="https://cf1.gatewaypeople.com/production/fae/image/asset/3816/desktop_Kids_homepage_desktop.jpg"
            />
            <source
              media="(min-width: 768px)"
              srcSet="https://cf1.gatewaypeople.com/production/fae/image/asset/3816/tablet_Kids_homepage_desktop.jpg"
            />
            <source
              media="(min-width: 576px)"
              srcSet="https://cf1.gatewaypeople.com/production/fae/image/asset/3816/mobile_Kids_homepage_desktop.jpg"
            />
            <img
              className="w-100"
              src="https://cf1.gatewaypeople.com/production/fae/image/asset/3816/square_Kids_homepage_desktop.jpg"
              alt="Kids Experience"
              itemProp="image"
            />
          </picture>
        )}

        {activeVideo && <VideoPlayer video={activeVideo} />}
      </header>

      <section
        id="series-details"
        className="content-body"
        data-turbolinks="false"
      >
        <div className="series-context">
          <div className="placeholder">
            <section className="details">
              <div className="container">
                <div className="row px-md-5">
                  <div className="col-md-8 offset-md-2">
                    <h1 itemProp="name">{content?.kids_experience?.title}</h1>
                    <meta
                      itemProp="datePublished"
                      content="Jan 27, 2022 12:00am"
                    />
                    <meta itemProp="keywords" content="Kids Experience" />
                    <meta
                      itemProp="url"
                      content="https://gatewaypeople.com/series/kids-experience"
                    />
                    <StructuredDataPublisher />
                    <div
                      itemProp="description"
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: content?.kids_experience?.body_html,
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="sermons">
              <div className="container">
                {!videos && (
                  <div className="row px-md-5 sermon">
                    <div className="col-md-8 offset-md-2">
                      <Skeleton height={23} className="w-full" />
                      <Skeleton height={14} width={100} className="mb-2" />
                      <Skeleton height={10} className="w-full" count={2} />
                      <Skeleton height={12} width={150} className="w-full" />
                    </div>
                  </div>
                )}
                {videos &&
                  videos?.map((video, i) => (
                    <div
                      key={i}
                      className={classNames("row px-md-5 sermon", {
                        active: activeVideo?.id === video?.id,
                      })}
                    >
                      <div
                        className="col-md-8 offset-md-2"
                        itemProp="hasPart"
                        itemScope
                        itemType="https://schema.org/Episode"
                      >
                        <meta
                          itemProp="datePublished"
                          content="May 05, 2023 07:30pm"
                        />
                        <meta
                          itemProp="url"
                          content="https://gatewaypeople.com/series/kids-experience?sermon=babies-gods-love"
                        />

                        <div key={i} className="media">
                          <div className="media-body">
                            <h4
                              itemProp="name"
                              data-sermon-seo-title="Babies: God’s Love"
                            >
                              {video?.snippet?.title}
                            </h4>
                            <div className="sequence">
                              <span itemProp="position">{i + 1}</span> of{" "}
                              {videos?.length}
                            </div>
                            <div className="speaker">
                              <span>by </span>
                              <author itemProp="author">Gateway Kids</author>
                            </div>
                            <div className="delivered-at">
                              {formatDateFromAPI(
                                video?.contentDetails?.videoPublishedAt ||
                                  video?.snippet?.publishedAt,
                                locale
                              )}
                            </div>
                            <meta
                              property="sermon_seo_description"
                              content={formatVideoDescription(
                                video?.snippet?.description
                              )}
                            />
                            <div className="description" itemProp="description">
                              <p>
                                {formatVideoDescription(
                                  video?.snippet?.description
                                )}
                              </p>
                            </div>
                            <div className="actions">
                              <figure className="figure pr-3 text-center">
                                <a
                                  className="play"
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setActiveVideo(video)
                                    // Scroll to #top anchor
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "smooth",
                                    })
                                  }}
                                >
                                  <i className="fal fa-play-circle fa-2x play-button" />
                                  <figcaption className="figure-caption">
                                    {content?.individual_sermon?.actions?.watch}
                                  </figcaption>
                                </a>
                              </figure>
                              {/* <figure className="figure pr-3 text-center">
                            <a
                              target="_blank"
                              href="https://gatewaychurchdl-a.akamaihd.net/Gateway_Church_Dallas/479/299/1714193475895.mp3"
                            >
                              <i className="fal fa-headphones-alt" />
                              <figcaption className="figure-caption">
                                Listen
                              </figcaption>
                            </a>{' '}
                          </figure> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </section>
            {/* <section className="related-content">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 offset-md-2">
                    <div className="row px-md-5">
                      <div className="col-12 divider" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-8 offset-md-2">
                    <h3 className="text-center text-uppercase mt-2">
                      Related Sermons
                    </h3>
                    <div className="row">
                      <div className="col-sm-6 col-lg-4 sermon">
                        <div
                          className="sermon-card-component"
                          data-turbolinks="false"
                        >
                          <a
                            className="card-link"
                            href="/sermons/46017-step-up-i-can-show-my-faith-when-i-trust-god"
                          />
                          <div className="sermon-card">
                            <div className="image-container">
                              <img
                                src="https://cf2.gatewaypeople.com/production/fae/image/asset/9420/mobile_1ee5a618-c7a3-602c-b4f9-85029a75890d_image.jpg"
                                alt="Step up: I can show my faith when I trust God"
                              />
                            </div>
                            <h2 className="h4">
                              Step up: I can show my faith when I trust God
                            </h2>
                            <div className="date">September 23, 2023</div>
                            <div className="speaker" />
                            <p className="description mt-1 mb-4">
                              I just love a good movie and a good book! But for
                              the most part, many of our favorite books and
                              movies are good stories, but they aren’t real. At
                              the end of the day, I might enjoy a movie or a
                              book, but they are not things to put a whole lot
                              of faith in. But I can put my faith...
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 sermon">
                        <div
                          className="sermon-card-component"
                          data-turbolinks="false"
                        >
                          <a
                            className="card-link"
                            href="/sermons/44591-youre-invited-i-can-tell-everyone-about-jesus"
                          />
                          <div className="sermon-card">
                            <div className="image-container">
                              <img
                                src="https://cf1.gatewaypeople.com/production/fae/image/asset/9393/mobile_1ee4dabf-6c5e-69de-b3f2-59f46922e852_image.jpg"
                                alt="You're Invited: I Can Tell Everyone About Jesus"
                              />
                            </div>
                            <h2 className="h4">
                              You're Invited: I Can Tell Everyone About Jesus
                            </h2>
                            <div className="date">September 9, 2023</div>
                            <div className="speaker" />
                            <p className="description mt-1 mb-4">
                              Everyone is invited into God’s family, but not
                              everyone knows about this or how to say yes to
                              this invitation to be in God’s family. How can
                              they know unless we tell them? When we tell people
                              about Jesus and the amazing things he did and
                              still does for us, we are inviting them...
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 sermon">
                        <div
                          className="sermon-card-component"
                          data-turbolinks="false"
                        >
                          <a
                            className="card-link"
                            href="/sermons/43892-youre-invited-jesus-invites-me-to-be-forgiven"
                          />
                          <div className="sermon-card">
                            <div className="image-container">
                              <img
                                src="https://cf1.gatewaypeople.com/production/fae/image/asset/9389/mobile_1ee3b9d0-5fde-647c-86dd-b93cf8c77f91_image.jpg"
                                alt="You're Invited: Jesus Invites me to be Forgiven"
                              />
                            </div>
                            <h2 className="h4">
                              You're Invited: Jesus Invites me to be Forgiven
                            </h2>
                            <div className="date">September 2, 2023</div>
                            <div className="speaker" />
                            <p className="description mt-1 mb-4">
                              Your mistakes do not disqualify you from being
                              included in God's family. When you choose to
                              accept Jesus' invitation, you are completely
                              forgiven!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* <section className="podcasts">
              <div className="container">
                <div className="row px-md-5">
                  <div className=" col-md-8 offset-md-2">
                    <div className="justify-content-center text-center podcast mt-5">
                      <a href="https://podcasts.apple.com/ca/podcast/gateway-church-audio-podcast/id88410531?mt=2">
                        {' '}
                        <img
                          src="https://cf1.gatewaypeople.com/podcasts/gwp-apple-podcasts-en.png"
                          className="podcast"
                          alt="Listen on Apple Podcasts"
                        />
                      </a>{' '}
                      <p>
                        Never miss a sermon. Subscribe on Apple Podcasts to get
                        new sermons as they become available.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
          </div>
        </div>
      </section>
    </div>
  )
}

export default Kids
