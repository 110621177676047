import React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import mediaQueries from '../../../lib/mediaQueries';

const GroupDetails = ({ group }) => {
  return (
    <div
      className={classNames(
        'd-flex flex-column justify-items-center',
        css`
          font-size: 0.8rem;

          ${mediaQueries.lg} {
            width: 350px;
          }

          h3 {
            font-size: 1.2rem;
          }

          strong {
            font-weight: 600;
          }

          .btn {
            font-size: inherit;
          }

          .pill {
            display: inline-block;
            background-color: rgba(255, 255, 255, 0.2);
            padding: 3px 11px;
            border-radius: 12px;
            font-weight: 600;
            color: inherit;
          }
        `
      )}
    >
      <h3 className="mb-2">{group.name}</h3>

      <div
        className="mb-2"
        dangerouslySetInnerHTML={{
          __html: group?.description,
        }}
      />

      {false && (group?.location || group?.address) && (
        <div className="mb-2">
          {group?.location && <strong>{group?.location}</strong>}
          {group?.address && (
            <address className="mb-0">{group.address}</address>
          )}
        </div>
      )}

      {group.public_church_center_web_url && <div className="mb-2">
        <a
          href={group.public_church_center_web_url}
          target="_blank"
          rel="noreferrer"
        >
          More about this gathering
        </a>
      </div>}

      <div className="">
        {group?.contact_email && (
          <a
            href={`mailto:${group.contact_email}`}
            className={classNames(
              'btn btn-primary',
              css`
                background-color: var(--colors-gw-blue);
                border-radius: 50px;
                border: 0;

                &:hover {
                  background-color: var(--colors-gw-blue);
                  filter: brightness(95%);
                }
              `
            )}
          >
            Contact Leader
          </a>
        )}
      </div>
    </div>
  );
};

export default GroupDetails;
