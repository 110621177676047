import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { youTubeChannelURL } from '../util/youtube'

const skeletonStyle = {
  className: 'w-full',
  baseColor: '#333',
  highlightColor: '#555',
}

function PlayerLoading({ locale, content }) { // eslint-disable-line no-unused-vars
  return (
    <>
      <a
        href={youTubeChannelURL}
        rel="noreferrer"
        target="_blank"
        className="d-block text-center mb-4"
      >
        {content?.live?.show?.video_trouble}
      </a>
      <div className="row align-items-lg-top latest-sermon">
        <div className="col-md-6 pr-lg-4 mb-2 mb-md-4">
          {/* Video Player */}
          <Skeleton
            {...skeletonStyle}
            style={{
              aspectRatio: '16 / 9',
            }}
          />
        </div>
        <div className="col-10 offset-1 col-md-6 offset-md-0 details">
          {/* Type of Video */}
          <Skeleton
            {...skeletonStyle}
            className="mb-2"
            width={130}
            height={14}
          />
          {/* Title */}
          <Skeleton
            {...skeletonStyle}
            className="mb-1 w-full"
            height={40}
            style={{
              width: '80%',
            }}
          />
          <Skeleton
            {...skeletonStyle}
            className="mb-2 w-full"
            height={40}
            style={{
              width: '60%',
            }}
          />
          {/* Date */}
          <Skeleton
            {...skeletonStyle}
            className="mb-3"
            width={80}
            height={12}
          />
          {/* Description */}
          <div className="mb-4">
            {[0, 1, 2, 3].map((i) => (
              <Skeleton key={i} {...skeletonStyle} height={16} />
            ))}
          </div>
          {/* <AdditionalLinks local={locale} content={content} /> */}
        </div>
      </div>
    </>
  )
}

export default PlayerLoading
