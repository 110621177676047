import React from "react"
import { useContent } from "../context/ContentProvider"

function TheNightShift() {
  const { content } = useContent()
  return (
    <section
      id="the-night-shift"
      className="christmas-2023__the-night-shift text-ivory py-4 pt-lg-8 pb-lg-6"
    >
      <div className="container">
        <div className="row">
          <div className="d-lg-none col-lg pl-lg-4">
            <img
              src={content?.the_night_shift?.image_mobile}
              alt={content?.the_night_shift?.title_html}
              className="christmas-2023__the-night-shift__imageMobile img-fluid w-full mb-4"
            />
          </div>
          <div className="col-lg">
            <h3 className="christmas-2023__the-night-shift__title font-chronicle font-italic text-gold mb-2">
              {content?.the_night_shift?.title_html}
            </h3>
            <time className="d-block christmas-2023__the-night-shift__date mb-3">
              {content?.the_night_shift?.date}
            </time>
            <div
              dangerouslySetInnerHTML={{
                __html: content?.the_night_shift?.description_html,
              }}
            />
            <hr />
            <h4 className="mb-1">
              {content?.the_night_shift?.reserve_seat?.title_html}
            </h4>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  content?.the_night_shift?.reserve_seat?.description_html,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: content?.the_night_shift?.reserve_seat?.dates_times,
              }}
              className="mb-4"
            />
            {content?.the_night_shift?.reserve_seat?.cta?.url && (
              <a
                href={content?.the_night_shift?.reserve_seat?.cta?.url}
                className="btn btn-primary"
                target={
                  content?.the_night_shift?.reserve_seat?.cta?.target || "_self"
                }
              >
                {content?.the_night_shift?.reserve_seat?.cta?.text}
              </a>
            )}
            {content?.the_night_shift?.interpretation?.title_html && (
              <>
                <hr />
                <h4 className="mb-1">
                  {content?.the_night_shift?.interpretation?.title_html}
                </h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      content?.the_night_shift?.interpretation
                        ?.description_html,
                  }}
                  className="mb-4"
                />
              </>
            )}
            {content?.the_night_shift?.livestream?.title_html && (
              <>
                <hr />
                <h4 className="mb-1">
                  {content?.the_night_shift?.livestream?.title_html}
                </h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      content?.the_night_shift?.livestream?.description_html,
                  }}
                  className="mb-4"
                />
              </>
            )}
          </div>
          <div className="d-none d-lg-block col-lg pl-lg-4">
            <img
              src={content?.the_night_shift?.image}
              alt={content?.the_night_shift?.title_html}
              className="christmas-2023__the-night-shift__image img-fluid w-full"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TheNightShift
