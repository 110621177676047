import React from 'react'
import { getPlaylistItems } from '../../../yt_live_player/util/youtube'
import LoaderRow from '../Loaders/LoaderRow'
import VideoCard from '../Video/VideoCard'

function PlaylistItems({ id, pagination = true, perPage = 25, locale = "en" }) {
  const [playlistItems, setPlaylistItems] = React.useState(null)
  const [itemsForPage, setItemsForPage] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  const loadPlaylistItems = React.useCallback(
    async (pageToken) => {
      setLoading(true)
      const response = await getPlaylistItems(
        id,
        Math.max(5, perPage),
        pageToken || null
      ) // We're manually setting a limit here because the API currently returns private videos, for some reason.
      if (response && !response.error && !response.errors) {
        setPlaylistItems(response)
        setItemsForPage(
          pageToken
            ? [...itemsForPage, ...(response?.items || [])]
            : response.items || []
        )
      }
      return setLoading(false)
    },
    [id, itemsForPage, perPage]
  )

  React.useEffect(() => {
    loadPlaylistItems()
  }, [id])

  if (!id) return null

  if (playlistItems && itemsForPage.length === 0)
    return <div>No videos found.</div>

  if (!playlistItems) return <LoaderRow />

  // By default this component returns a row of cards/videos that are present in a playlist
  return (
    <>
      <div className="row">
        {itemsForPage
          .filter((item) => item?.status?.privacyStatus === "public")
          .map((video, i) => (
            <div key={i} className="col-md-4">
              <VideoCard
                video={video}
                playlistID={id}
                locale={locale}
                structuredData={{
                  position: i + 1,
                  itemCount: itemsForPage.length,
                }}
              />
            </div>
          ))}
      </div>
      {loading && <LoaderRow />}
      {!loading && pagination && playlistItems?.nextPageToken && (
        <button
          className="btn btn-secondary mx-auto d-block mb-4"
          onClick={() => loadPlaylistItems(playlistItems?.nextPageToken)}
        >
          Load More
        </button>
      )}
    </>
  )
}

export default PlaylistItems
