import React from 'react';
import { css } from '@emotion/css';
import MapGL, { NavigationControl, GeolocateControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const mapStyles = {
  light: 'mapbox://styles/mapbox/light-v10',
  dark: 'mapbox://styles/mapbox/dark-v9',
  streets: 'mapbox://styles/mapbox/streets-v11',
  outdoors: 'mapbox://styles/mapbox/outdoors-v11',
  dark10: 'mapbox://styles/mapbox/dark-v10',
  satellite: 'mapbox://styles/mapbox/satellite-v9',
  satelliteStreets: 'mapbox://styles/mapbox/satellite-streets-v11',
};

// eslint-disable-next-line react/display-name
const Map = React.forwardRef(
  (
    {
      viewport,
      setViewport,
      interactiveLayerIds,
      onClick,
      onLoad,
      children,
      maxZoom,
      mapStyle,
      geoLocate,
    },
    ref
  ) => {
    // Ensure that the zoom level is within the proper range
    const updateViewport = (viewport) => {
      viewport.zoom = viewport.zoom > maxZoom ? maxZoom : viewport.zoom;
      setViewport({ ...viewport });
    };

    // Load the icon assets, only necessary for Source layers
    // eslint-disable-next-line no-unused-vars
    const onMapLoad = (event) => {
      const map = event.target;
      map.loadImage('/assets/icons/group-icon.png', (error, image) => {
        if (error) throw error;
        return map.addImage('groupIcon', image);
      });
    };

    return (
      <MapGL
        width="100%"
        height="50vw"
        attributionControl={false}
        mapboxApiAccessToken={process.env.MAPBOX_TOKEN}
        mapStyle={mapStyle in mapStyles ? mapStyles[mapStyle] : mapStyle}
        onViewportChange={updateViewport}
        interactiveLayerIds={interactiveLayerIds}
        scrollZoom={false}
        ref={ref}
        onClick={onClick}
        onLoad={(e) => {
          // onMapLoad(e);
          onLoad(e);
        }}
        className={css`
          .mapboxgl-ctrl-logo {
            display: none;
          }
        `}
        {...viewport}
      >
        {geoLocate && (
          <GeolocateControl
            positionOptions={{ enableHighAccuracy: false }}
            onViewportChange={updateViewport}
            trackUserLocation={false}
            className={css`
              right: 1rem;
              bottom: calc(1rem + 65px);
            `}
          />
        )}
        {children}
        <NavigationControl
          showCompass={false}
          className={css`
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            max-width: 29px;
            z-index: 10000;
          `}
        />
      </MapGL>
    );
  }
);

Map.defaultProps = {
  onClick: () => {},
  onLoad: () => {},
  maxZoom: 13,
  mapStyle: 'dark',
  geoLocate: false,
  interactiveLayerIds: [],
};

export default Map;
