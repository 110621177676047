import React from 'react'
import LoaderCard from './LoaderCard'

function LoaderRow({ cardCount = 3 }) {
  return (
    <div className="row align-items-center">
      {Array.from({ length: cardCount }).map((_, index) => (
        <div className="col-md-4" key={index}>
          <LoaderCard key={index} />
        </div>
      ))}
    </div>
  )
}

export default LoaderRow
