import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import FeaturedSection from "./FeaturedSection/FeaturedSection"
import { NotificationProvider } from "../../context/NotificationContext"
import FeaturedSermon from "./FeaturedSermon/FeaturedSermon"
import { LivestreamProvider } from "./context/LivestreamContext"
import { SettingsProvider } from "../../context/SettingsContext"

function App({ locale, content, featuredSections, env, youTubeIds }) {
  const settings = JSON.parse(youTubeIds)

  return (
    <Router>
      <SettingsProvider youtube_ids={settings?.data?.attributes} env={env}>
        <LivestreamProvider locale={locale} env={env}>
          <NotificationProvider>
            <FeaturedSermon locale={locale} content={content} />
            <FeaturedSection sections={featuredSections} />
          </NotificationProvider>
        </LivestreamProvider>
      </SettingsProvider>
    </Router>
  )
}

export default App
