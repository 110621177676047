import React from 'react';
import { Popup } from 'react-map-gl';
import { css } from '@emotion/css';

const GWPopup = ({
  children,
  longitude,
  latitude,
  onClose,
  anchor,
  variant,
  offsetTop,
  ...rest
}) => {
  return (
    <Popup
      anchor={anchor}
      longitude={longitude}
      latitude={latitude}
      offsetTop={offsetTop}
      onClose={onClose}
      className={css`
        color: ${variant === 'dark' ? 'white' : '#414042'};
        will-change: opacity;
        transition: opacity 0.5s ease;
        opacity: ${latitude ? '1' : '0'};

        .mapboxgl-popup-content {
          border-radius: 0;
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
          padding: 1.5rem;
          box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.2);
          background-color: ${variant === 'dark'
            ? 'rgba(10, 10, 10, 0.95)'
            : 'rgb(255,255,255,)'};
          color: ${variant === 'dark' ? 'white' : '#414042'};
        }

        .mapboxgl-popup-close-button {
          color: inherit;
          margin-top: 10px;
          margin-right: 10px;
        }

        &.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
          border-bottom-color: ${variant === 'dark'
            ? 'rgba(10, 10, 10, 0.9)'
            : 'rgb(255,255,255)'} !important;
        }

        &.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
          border-bottom-color: ${variant === 'dark'
            ? 'rgba(10, 10, 10, 0.9)'
            : 'rgb(255,255,255)'} !important;
        }

        &.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
          border-bottom-color: ${variant === 'dark'
            ? 'rgba(10, 10, 10, 0.9)'
            : 'rgb(255,255,255)'} !important;
        }

        &.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
          border-left-color: ${variant === 'dark'
            ? 'rgba(10, 10, 10, 0.9)'
            : 'rgb(255,255,255)'} !important;
        }

        &.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
          border-top-color: ${variant === 'dark'
            ? 'rgba(10, 10, 10, 0.9)'
            : 'rgb(255,255,255)'} !important;
        }

        &.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
          border-right-color: ${variant === 'dark'
            ? 'rgba(10, 10, 10, 0.9)'
            : 'rgb(255,255,255)'} !important;
        }
      `}
      {...rest}
    >
      {children}
    </Popup>
  );
};

GWPopup.defaultProps = {
  variant: 'dark',
  anchor: 'top',
  offsetTop: 35,
};

export default GWPopup;
