import React, { Suspense } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
const Map = React.lazy(() => import('./Map'));

function App({ campuses, gatherings, adminOffices, prisonCampuses, locale }) {
  const formattedCampuses = React.useMemo(() => {
    return campuses.map((c) => {
      return {
        ...c.attributes,
        type: 'campus',
      };
    })
  }, [campuses]);

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Map
          campuses={formattedCampuses}
          gatherings={gatherings}
          locale={locale}
          adminOffices={adminOffices}
          prisonCampuses={prisonCampuses}
        />
      </Suspense>
    </Router>
  );
}

export default App;
