import CampusServiceTimes from './CampusServiceTimes';
import React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import helpers from '../../../lib/helpers';
import mediaQueries from '../../../lib/mediaQueries';

const CampusDetails = ({ campus, locale }) => {
  return (
    <div
      className={classNames(
        'd-flex flex-column justify-items-center',
        css`
          font-size: 0.8rem;

          ${mediaQueries.lg} {
            width: 350px;
          }

          .image {
            min-width: 120px;
            height: 120px;
            background-size: cover;
            background-position: center center;
          }

          h3 {
            font-size: 1rem;
          }

          strong {
            font-weight: 600;
          }

          .btn {
            font-size: inherit;
          }

          .pill {
            display: inline-block;
            background-color: rgba(255, 255, 255, 0.2);
            padding: 3px 11px;
            border-radius: 12px;
            font-weight: 600;
            color: inherit;
          }
        `
      )}
    >
      <div className="d-flex">
        {campus?.image && (
          <div
            className="image mr-3"
            style={{ backgroundImage: `url(${campus.image})` }}
          />
        )}
        <div>
          <h3 className={classNames('mb-2')}>
            {helpers.translateField(campus, 'name', locale)}
          </h3>

          <div className="mb-2">
            {campus?.address_line_one && (
              <address className="mb-0">
                <span>
                  {campus.address_line_one}
                  <br />
                </span>
                {campus.address_line_two && (
                  <span>
                    {campus.address_line_two}
                    <br />
                  </span>
                )}
                {campus.city}, {campus.state} {campus.zip}
              </address>
            )}
          </div>

          {campus.serviceTimes && (
            <div className="mb-2">
              <CampusServiceTimes serviceTimes={campus.serviceTimes} />
            </div>
          )}
          {campus?.locationType == "campus" && <div className="mb-2">
            <a
              href={`plan-a-visit/${campus.slug}`}
              className="btn btn-secondary btn-sm"
            >
              Plan your visit
            </a>
          </div>&&
          <div>
            <a href={`campuses/${campus.slug}`}>
              More about this campus&nbsp;
              <i className="fas fa-chevron-right align-middle" />
            </a>
          </div>}

          {campus?.location_type == "prison_campus" && <div className="mb-2">
            <a href="/ministries/prison-ministry">
              More about our prison ministry
              <i className="fas fa-chevron-right align-middle" />
            </a>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default CampusDetails;
