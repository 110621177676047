import React from "react"
import SermonLoading from "./SermonLoading"
import {
  formatDateFromAPI,
  formatVideoDescription,
  formatVideoTitle,
  getBibleDotComURL,
  getSpeakerName,
  getVideo,
} from "../../yt_live_player/util/youtube"
import PlaylistItems from "../components/Playlist/PlaylistItems"
import { Helmet } from "react-helmet"
import StructuredDataPublisher from "../../shared/StructuredData/StructuredDataPublisher"
import VideoPlayer from "../components/Video/VideoPlayer"
import { SettingsProvider, useSettings } from "../../../context/SettingsContext"

function Sermon({ locale, content, id, playlistID }) {
  const { youTubeIds } = useSettings()
  console.log(youTubeIds)
  const [video, setVideo] = React.useState(null)

  const relatedItemsDisplayHandler = (total) => {
    if (total > 3) {
      $("#related-sermons-view-all").removeClass("d-none")
    }
  }

  const {
    publishDate,
    videoTitle,
    videoDescription,
    videoThumbnail,
    videoKeywords,
  } = React.useMemo(() => {
    if (!video) return {}
    return {
      publishDate:
        video?.contentDetails?.videoPublishedAt || video?.snippet?.publishedAt,
      videoTitle: formatVideoTitle(video?.snippet?.localized?.title),
      videoDescription: formatVideoDescription(
        video?.snippet?.localized?.description
      ),
      videoThumbnail:
        video?.snippet?.thumbnails?.high?.url ||
        video?.snippet?.thumbnails?.medium?.url,
      videoKeywords: video?.snippet?.tags?.join(", "),
    }
  }, [video])

  React.useEffect(() => {
    const fetchVideo = async () => {
      const response = await getVideo(id)
      if (!response?.error && !response?.errors)
        setVideo(response?.items?.[0] || null)
    }

    fetchVideo()
  }, [id])

  React.useEffect(() => {
    if (!video) return

    // Make sure the video belongs to one of our approved channels
    if (
      !Object.values(youTubeIds.channel).includes(video?.snippet?.channelId)
    ) {
      window.location.href = `/sermons`
    }
  }, [video])

  if (!video) return <SermonLoading />

  return (
    <div
      className="sermons"
      itemScope
      itemType="https://schema.org/CreativeWork"
    >
      <Helmet>
        <title>{`${videoTitle} | Gateway Church`}</title>
        <meta name="title" content={videoTitle} />
        <meta property="og:title" content={videoTitle} />
        <meta property="og:type" content="video.other" />
        <meta name="twitter:card" content="summary" />
        <meta name="description" content={videoDescription} />
        <meta property="og:description" content={videoDescription} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content={locale} />
        <meta property="og:site_name" content="Gateway Church" />
        <meta property="og:image" content={videoThumbnail} />
        <meta property="og:video:tag" content={videoKeywords} />
        <meta name="gwp_type" content="sermon" />
        <meta property="data-search-priority" content="50" />
        <meta property="data-search-featured" content="false" />
        <meta itemProp="keywords" content={videoKeywords} />
        <meta
          property="og:video:duration"
          content={video?.contentDetails?.duration}
        />
        <meta
          property="og:video:release_date"
          content={formatDateFromAPI(publishDate)}
        />
      </Helmet>
      <header className="featured-image gwp-flex-container">
        <VideoPlayer video={video} />
      </header>
      <section id="sermon-details">
        <div className="sermon-context">
          <div className="placeholder">
            <section className="details">
              <div className="container">
                <div className="row px-md-5">
                  <div className="col-md-8 offset-md-2">
                    <StructuredDataPublisher />
                    {/* TODO: Update with actual data */}
                    <meta itemProp="datePublished" content={publishDate} />
                    <meta itemProp="url" content={window.location.href} />
                    <meta itemProp="inLanguage" content="en" />
                    <meta
                      itemProp="timeRequired"
                      content={video?.contentDetails?.duration}
                    />
                    <meta itemProp="image" src={videoThumbnail} />
                    <div
                      className="d-none"
                      itemProp="video"
                      itemScope
                      itemType="https://schema.org/VideoObject"
                    >
                      <meta itemProp="name" content={videoTitle} />
                      <meta itemProp="description" content={videoDescription} />
                      {getSpeakerName(video) && (
                        <meta
                          itemProp="speaker"
                          content={getSpeakerName(video)}
                        />
                      )}
                      <meta itemProp="url" content={window.location.href} />
                      <meta itemProp="thumbnailUrl" content={videoThumbnail} />
                      <meta
                        itemProp="contentUrl"
                        content={`https://www.youtube.com/embed/${video?.id}`}
                      />
                      <meta itemProp="encodingFormat" content="video/mp4" />
                      <meta
                        itemProp="duration"
                        content={video?.contentDetails?.duration}
                      />
                      <meta itemProp="uploadDate" content={publishDate} />
                      <meta itemProp="inLanguage" content="en" />
                    </div>
                    {/* <div
                      className="d-none"
                      itemProp="audio"
                      itemScope=""
                      itemType="https://schema.org/AudioObject"
                    >
                      <meta
                        itemProp="name"
                        content={formatVideoTitle(
                          video?.snippet?.localized?.title
                        )}
                      />
                      <meta
                        itemProp="description"
                        content={formatVideoDescription(
                          video?.snippet?.localized?.description
                        )}
                      />
                      <meta itemProp="keywords" content="" />
                      <meta itemProp="url" content={window.location.href} />
                      <meta
                        itemProp="contentUrl"
                        content={`https://www.youtube.com/embed/${video?.id}`}
                      />
                      <meta itemProp="encodingFormat" content="audio/mpeg" />
                      <meta itemProp="duration" content="PT34M" />
                      <meta
                        itemProp="uploadDate"
                        content="Sep 09, 2023 12:00am"
                      />
                      <meta itemProp="inLanguage" content="en" />
                    </div> */}

                    <h1 itemProp="name">{videoTitle}</h1>
                    <div className="published-at mt-n1">
                      {formatDateFromAPI(publishDate, locale)}
                    </div>
                    {getSpeakerName(video) && (
                      <div className="speaker">{getSpeakerName(video)}</div>
                    )}
                    <div className="description" itemProp="description">
                      {videoDescription}
                    </div>

                    {/* TODO: Figure out how we're going to handle Spanish sermons */}
                    {/* <a
                      className="d-block read-more mb-2 mt-2"
                      data-turbolinks="false"
                      href="/es/sermons/es-44623-camina?ic=true"
                    >
                      Español&nbsp;
                      <i className="fas fa-chevron-right align-middle"></i>
                    </a> */}

                    <div className="actions">
                      {/* <figure className="figure pr-3 text-center">
                        <a
                          data-turbolinks="false"
                          download="true"
                          href="https://pmd.broadcastcloud.tv/8/44622/44622_af653df0cf41727208ac7b2a71a8be5d_audio.mp3"
                        >
                          <i className="fal fa-headphones-alt"></i>
                          <figcaption className="figure-caption">
                            Listen
                          </figcaption>
                        </a>{' '}
                      </figure> */}
                      {getBibleDotComURL(video?.snippet?.description) && (
                        <figure className="figure pr-3 text-center">
                          <a
                            data-turbolinks="false"
                            rel="noreferrer"
                            target="_blank"
                            href={getBibleDotComURL(
                              video?.snippet?.description
                            )}
                          >
                            <i className="fal fa-edit"></i>
                            <figcaption className="figure-caption">
                              {content?.individual_sermon?.actions?.notes}
                            </figcaption>
                          </a>
                        </figure>
                      )}
                      {/* TODO: Figure out how we're going to handle the discussion guide */}
                      {/* <figure className="figure pr-3 text-center">
                        <a
                          data-turbolinks="false"
                          target="_blank"
                          type="application/pdf"
                          className="no-icon"
                          href="https://cf2.gatewaypeople.com/production/fae/file/asset/5959/1ee50d82-bf50-6142-b1e4-250bfdc88aca.pdf"
                        >
                          <i className="fal fa-file-alt"></i>
                          <figcaption className="figure-caption">
                            Discuss
                          </figcaption>
                        </a>{' '}
                      </figure> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      {playlistID && (
        <div className="container my-4">
          <div className="d-flex align-items-end">
            <h3 className="mb-3">{content?.related_sermons?.html_title}</h3>
            <a
              id="related-sermons-view-all"
              data-turbolinks="false"
              className="ml-2 ml-sm-3 ml-lg-5 mb-3 d-none"
              href={`${
                locale === "en" ? "/" : "/es/"
              }sermons/playlist/${playlistID}`}
            >
              {content?.index?.view_all}&nbsp;
              <i className="fas fa-chevron-right align-middle"></i>
            </a>
          </div>
          <PlaylistItems
            id={playlistID}
            limit={3}
            currentVideoID={id}
            onRelatedItemsDisplayed={relatedItemsDisplayHandler}
          />
        </div>
      )}
    </div>
  )
}

export default function WrappedSermon({
  locale,
  content,
  id,
  playlistID,
  youTubeIds,
}) {
  const settings = JSON.parse(youTubeIds)

  return (
    <SettingsProvider youtube_ids={settings?.data?.attributes}>
      <Sermon
        {...{
          locale,
          content,
          id,
          playlistID,
        }}
      />
    </SettingsProvider>
  )
}
