import React from "react"
import Intro from "../components/Intro"
// import Dates from "../components/Dates"
// import Events from "../components/Events"
// import TheNightShift from "../components/TheNightShift"
// import Candlelight from "../components/Candlelight"
// import Give from "../components/Give"
import FutureChristmas from "../components/FutureChristmas"
import { useContent } from "../context/ContentProvider"
// import CampusPastor from "../components/CampusPastor"

function Christmas2023({ locale, content, campuses }) {
  const { setContent, setLocale, setCampuses } = useContent()

  React.useEffect(() => {
    setContent(content)
    setLocale(locale)
    setCampuses(content?.dates?.dates)
  }, [locale, content, campuses])

  const sections = [
    Intro,
    FutureChristmas,
    // Dates,
    // Events,
    // TheNightShift,
    // CampusPastor,
    // Candlelight,
    // Give,
  ]

  return (
    <>
      {sections.map((Section, index) => (
        <Section key={index} />
      ))}
    </>
  )
}

export default Christmas2023
