import Card from './Card';
import React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import useBreakpoint from '../../lib/useBreakpoint';

// eslint-disable-next-line no-unused-vars
function Results({ results, activeFilters, locale }) {
  const resultsPerPage = 8;
  const breakpoint = useBreakpoint();
  const [maxResults, setMaxResults] = React.useState(resultsPerPage);

  if (results.length < 1) return <div className="text-center mt-4">Opportunities coming soon!</div>

  return (
    <div>
      <div
        className={classNames(css`
          display: grid;
          grid-template-columns: ${breakpoint.isLgUp
            ? 'repeat(2, 1fr)'
            : '1fr'};
          gap: 2rem;
        `)}
      >
        {results.slice(0, maxResults).map((result, i) => (
          <Card opportunity={result} key={i} locale={locale} />
        ))}
      </div>
      {results.length > maxResults && (
        <div className="mt-4">
          <button
            className={classNames(
              'mx-auto d-block',
              css`
                font-size: 18px;
                background: transparent;
                border: none;
                appearance: none;
                color: var(--colors-gw-blue);
                text-decoration: underline;
              `
            )}
            onClick={() => setMaxResults(maxResults + resultsPerPage)}
          >
            Load More
            <i className="far fa-arrow-down ml-2" />
          </button>
        </div>
      )}
    </div>
  );
}

export default Results;
