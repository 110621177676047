import React from "react"
import classNames from "classnames"
import FeaturedSectionCard from "./FeaturedSectionCard"
import slugify from "slugify"
import {
  formatDateFromAPI,
  formatVideoDescription,
  formatVideoTitle,
} from "../util/youtube"
import Countdown from "react-countdown"
import { useNotification } from "../../../context/NotificationContext"
import { useLivestream } from "../context/LivestreamContext"

function FeaturedSection({ sections }) {
  const { notifications, setNotifications } = useNotification()
  const {
    secondaryLiveEvent,
    setSecondaryLiveEvent,
    upcomingSecondaryEvent: upcomingSecondaryLiveEvent,
    setUpcomingSecondaryEvent: setUpcomingSecondaryLiveEvent,
    loadSecondaryLiveEvent,
    loadUpcomingSecondaryLiveEvent,
    DEBUG,
  } = useLivestream()

  React.useEffect(() => {
    loadSecondaryLiveEvent()
    loadUpcomingSecondaryLiveEvent()
  }, [])

  React.useEffect(() => {
    if (!secondaryLiveEvent) return
    setNotifications([
      ...notifications,
      {
        title: "Live Service",
        description: "We are currently live. Join us for our live service.",
        cta: {
          title: "Watch Now",
          href: "/sermons",
        },
      },
    ])
  }, [secondaryLiveEvent])

  const featuredSectionsForPage = React.useMemo(() => {
    if (!sections) return []
    return secondaryLiveEvent
      ? [
          {
            image: "home_page/Plan_a_Visit-card.jpg",
            title: secondaryLiveEvent?.snippet?.title,
            subtitle: "",
            body: formatVideoDescription(
              secondaryLiveEvent?.snippet?.description
            ),
            link_text: "View more sermons",
            path: "/sermons",
            video: secondaryLiveEvent,
            onVideoEnd: () => {
              setSecondaryLiveEvent(null)
            },
          },
          ...sections.slice(0, 2),
        ]
      : sections
  }, [sections, secondaryLiveEvent])

  return (
    <>
      <div className="row recent-sermons pb-4 mt-lg-6 mt-6">
        {featuredSectionsForPage.map((section) => (
          <FeaturedSectionCard
            key={slugify(section.title)}
            section={section}
            upcomingSecondaryLiveEvent={upcomingSecondaryLiveEvent}
          />
        ))}
      </div>
      {upcomingSecondaryLiveEvent && (
        <div
          className={classNames("d-none mt-3 p-2 pb-0 text-sm text-left", {
            "d-block": DEBUG,
          })}
          style={{
            backgroundColor: "rgba(0,0,0,0.3)",
            borderRadius: "6px",
          }}
        >
          <strong
            className="d-block mb-1"
            style={{
              fontSize: "0.825rem",
            }}
          >
            Upcoming Live Secondary Service
          </strong>
          <div className="d-flex align-items-center">
            {upcomingSecondaryLiveEvent?.snippet?.thumbnails?.default?.url && (
              <img
                src={
                  upcomingSecondaryLiveEvent?.snippet?.thumbnails?.default?.url
                }
                className="mr-2 mb-0"
              />
            )}
            <div>
              <div className="font-bold text-balance">
                {formatVideoTitle(upcomingSecondaryLiveEvent?.snippet?.title)}
              </div>
              <time>
                {formatDateFromAPI(
                  upcomingSecondaryLiveEvent?.snippet?.scheduledStartTime,
                  locale
                )}
              </time>
              <div>
                <Countdown
                  date={upcomingSecondaryLiveEvent?.snippet?.scheduledStartTime}
                  onComplete={() => {
                    setSecondaryLiveEvent(upcomingSecondaryLiveEvent)
                    setUpcomingSecondaryLiveEvent(null)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default FeaturedSection
