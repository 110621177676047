import React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';

function CampusServiceTimes({ serviceTimes }) {
  return (
    <ul
      className={classNames(
        'm-0 p-0',
        css`
          list-style: none;
        `
      )}
    >
      {Object.keys(serviceTimes).map((s) => {
        return serviceTimes[s] ? (
          <li>
            <strong className="text-capitalize">{s}</strong>: {serviceTimes[s]}
          </li>
        ) : null;
      })}
    </ul>
  );
}

export default CampusServiceTimes;
