import React from "react"
import { useContent } from "../context/ContentProvider"

function FutureChristmas() {
  const { content } = useContent()
  return (
    <section className="christmas-2023__future-christmas px-4 pt-4 pt-lg-6 pb-lg-4">
      <h2 className="text-center text-gold font-chronicle mb-4">
        {content?.future_christmas?.title }
      </h2>
    <div className="christmas-2023__future-christmas__grid mx-auto pb-4" dangerouslySetInnerHTML={{ __html: content?.future_christmas?.body_html }} />
  </section>

  )
}

export default FutureChristmas
