import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ServeWeekFilters from './ServeWeek';

function App({ locale, campuses, events, occurrences }) {
  return (
    <Router>
      <ServeWeekFilters
        locale={locale}
        campuses={campuses}
        events={events}
        occurrences={occurrences}
      />
    </Router>
  );
}

export default App;
