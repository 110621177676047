import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import GatheringsMap from './GatheringsMap-v2';

function App({ gatherings, gathering, locale }) {
  return (
    <Router>
      <GatheringsMap
        gatherings={gatherings}
        currentGathering={gathering}
        locale={locale}
      />
    </Router>
  );
}

export default App;
