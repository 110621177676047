import React from "react"
import { getVideo } from "../util/youtube"
import Player from "../Player/Player"
import PlayerLoading from "../Player/PlayerLoading"
import { useLivestream } from "../context/LivestreamContext"

function FeaturedSermon({ locale, content }) {
  const {
    primaryLiveEventIsLoading,
    loadPrimaryLiveEvent,
    loadUpcomingPrimaryEvent,
    primaryLiveEvent,
    setPrimaryLiveEvent,
    primaryIsLiveEvent,
    setPrimaryIsLiveEvent,
    upcomingPrimaryEvent,
    setUpcomingPrimaryEvent,
  } = useLivestream()

  React.useEffect(() => {
    loadPrimaryLiveEvent()
    loadUpcomingPrimaryEvent()
  }, [])

  return (
    <>
      {primaryLiveEventIsLoading || !primaryLiveEvent ? (
        <PlayerLoading content={content} locale={locale} />
      ) : (
        <Player
          sermon={primaryLiveEvent}
          isLiveService={primaryIsLiveEvent}
          upcomingService={upcomingPrimaryEvent}
          content={content}
          locale={locale}
          setLatestSermon={setPrimaryLiveEvent}
          setIsLiveService={setPrimaryIsLiveEvent}
          setUpcomingService={setUpcomingPrimaryEvent}
          onVideoEnd={async () => {
            // When the live service ends grab the video data and set it as the latestSermon
            if (primaryIsLiveEvent && primaryLiveEvent?.id) {
              const video = await getVideo(primaryLiveEvent?.id)
              if (video?.items?.length > 0) {
                setIsLiveService(false)
                setPrimaryLiveEvent(video?.items?.[0])
              }
            }
          }}
        />
      )}
    </>
  )
}

export default FeaturedSermon
