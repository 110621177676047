import React from "react"
import { useContent } from "../context/ContentProvider"

function Intro() {
  const { content } = useContent()

  return (
    <section
      className="christmas-2023__intro text-center py-4 bg-cover bg-bottom bg-no-repeat"
      style={{
        backgroundImage: `url(https://cf1.gatewaypeople.com/static/christmas-2023/2312_ChristmasCandlelight_ID-XL.jpg)`,
      }}
    >
      <div className="up-title text-ivory mb-3 mb-lg-6">

      </div>
      <h1
        className="christmas-2023__intro__title font-chronicle uppercase text-gold mb-1"
        dangerouslySetInnerHTML={{
          __html: content?.intro?.title_html,
        }}
      />
      <div className="up-title text-gold">{content?.intro?.subtitle}</div>
    </section>
  )
}

export default Intro
