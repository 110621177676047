import React from 'react'

// eslint-disable-next-line no-unused-vars
function OperationChristmasChild({ locale, campuses, content }) {
  const [selectedCampus, setSelectedCampus] = React.useState("SLK");
  
  const link = React.useMemo(() => {
    return content.cta?.[`link_${selectedCampus}`] || content.cta?.link;
  }, [selectedCampus]);

  return (
    <div>
      <select
        onChange={(e) => setSelectedCampus(e.target.value)}
        className="form-control mb-3"
      >
        {campuses.map((c, i) => (
          <option key={i} value={c.campus_abbreviation}>
            {c.name_en}
          </option>
        ))}
      </select>

      {selectedCampus !== "" && <a href={link} rel="noreferrer" className="text-wheat uppercase underline fw-500 christmas-2022__cta" target="_blank">{content.cta.title}<i className="fal fa-angle-right"></i></a>}
    </div>
  )
}

export default OperationChristmasChild
