/**
 * FilterOverlay.jsx
 * This component is used for displaying the filters on mobile devices.
 */

import FilterGroups from './FilterGroups';
import React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';

function FilterOverlay({
  filters,
  activeFilters,
  setActiveFilters,
  setFiltersVisible,
  filteredResults,
}) {
  return (
    <div
      className={classNames(
        'd-flex flex-column bg-white',
        css`
          position: fixed;
          inset: 0;
          z-index: 5000000;
          overflow-y: auto;
          height: 100vh;
          padding: 12vmin;
        `
      )}
    >
      <div
        className={css`
          flex: 1;
        `}
      >
        <h3
          className={classNames(
            'text-serve-dark-blue font-bold',
            css`
              letter-spacing: 4.4px;
              font-size: 22px;
              line-height: 29.3px;
            `
          )}
        >
          FILTER BY
        </h3>
        <FilterGroups
          filters={filters}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          setFiltersVisible={setFiltersVisible}
          filteredResults={filteredResults}
        />
      </div>
      <div
        className={classNames(
          'd-flex justify-content-between',
          css`
            position: fixed;
            bottom: 12vmin;
            left: 12vmin;
            right: 12vmin;
          `
        )}
      >
        <button
          className={classNames(
            'btn btn-secondary mr-4',
            css`
              padding: 0.75rem 2rem;
            `
          )}
          onClick={() => setFiltersVisible(false)}
        >
          Cancel
        </button>
        <button
          className={classNames(
            'btn btn-primary',
            css`
              padding: 0.75rem 2rem;
            `
          )}
          onClick={() => setFiltersVisible(false)}
        >
          Apply
        </button>
      </div>
    </div>
  );
}

export default FilterOverlay;
