import React from "react"
import YouTube from "react-youtube"
import classNames from "classnames"
import { css } from "@emotion/css"
import {
  formatDateFromAPI,
  formatVideoDescription,
  formatVideoTitle,
  getCompletedServices,
  isSecondaryService,
} from "../util/youtube"
import Countdown from "react-countdown"
import YouTubePlayButton from "../../shared/YouTubePlayButton"
import { useLivestream } from "../context/LivestreamContext"
import { isFriday, isWeekend } from "../util/date"
import { useSettings } from "../../../context/SettingsContext"

function Player({
  sermon,
  isLiveService,
  upcomingService,
  content,
  locale,
  onVideoEnd = () => {},
  setLatestSermon = () => {},
  setIsLiveService = () => {},
  setUpcomingService = () => {},
}) {
  const { DEBUG } = useLivestream()
  const { youTubeIds, env } = useSettings()
  const [showPlayer, setShowPlayer] = React.useState(true)
  const [videoPlayer, setVideoPlayer] = React.useState(null)
  const [recentLiveService, setRecentLiveService] = React.useState(null)
  const opts = React.useMemo(() => {
    return {
      height: "auto",
      width: "100%",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: isLiveService ? 1 : 0,
        controls: 1,
        mute: isLiveService ? 1 : 0,
      },
    }
  }, [isLiveService])

  // Set the most recent live service. This doesn't actually modify the player itself but allows a link to the full service to be displayed.
  React.useEffect(() => {
    if (!env?.showSermonOnly || !youTubeIds.playlist.weekend_livestreams) return

    const loadMostRecentService = async () => {
      if (isFriday() || isWeekend()) return

      const weekendServices = await getCompletedServices(youTubeIds.playlist.weekend_livestreams)
      if (weekendServices?.items?.length > 0) {
        return setRecentLiveService(
          weekendServices.items.find(
            (service) => !isSecondaryService(service.snippet.title)
          ) || null
        )
      }
    }

    loadMostRecentService()
  }, [youTubeIds])

  const onPlayerReady = React.useCallback(
    (event) => {
      setVideoPlayer(event)
      // access to player in all event handlers via event.target
      // return event.target.pauseVideo()
      if (isLiveService) {
        setShowPlayer(true)
        setTimeout(() => {
          event.target.playVideo()
        }, 500)
      }
    },
    [isLiveService]
  )

  React.useEffect(() => {
    setShowPlayer(isLiveService)
  }, [isLiveService])

  DEBUG && console.log({ recentLiveService })

  return (
    <div className="latest-sermons">
      <div className="row align-items-lg-top latest-sermon">
        <div className="col-md-6 pr-lg-4 mb-2 mb-md-4">
          {isLiveService && <span className="live-bug">Live</span>}
          <div
            className="w-full flex-1"
            style={{
              maxWidth: "100%",
              flex: "1",
              aspectRatio: "16 / 9",
              position: "relative",
              display: showPlayer ? "block" : "none",
            }}
          >
            <YouTube
              loading="eager"
              videoId={sermon?.snippet?.resourceId?.videoId || sermon?.id}
              opts={opts}
              onReady={onPlayerReady}
              className={classNames(
                "w-full p-absolute",
                css`
                  aspect-ratio: 16/9;

                  & iframe {
                    width: 100%;
                    height: 100%;
                  }
                `
              )}
              style={{
                width: "100%",
                height: "100%",
                inset: 0,
                position: "absolute",
              }}
              onEnd={onVideoEnd}
            />
          </div>
          <div
            className={classNames(
              "w-full video-click-to-play flex-col align-items-center justify-content-center p-2",
              {
                "d-none": showPlayer,
                "d-flex": !showPlayer,
              }
            )}
            onClick={() => {
              setShowPlayer(true)
              videoPlayer?.target?.playVideo()
            }}
            style={{
              backgroundImage: `url(${
                sermon?.snippet?.thumbnails?.maxres?.url ||
                sermon?.snippet?.thumbnails?.standard?.url ||
                sermon?.snippet?.thumbnails?.high?.url
              })`,
            }}
          >
            <YouTubePlayButton className="play-button" />
          </div>
          {!isLiveService && upcomingService && (
            // Note, this widget is being hidden but is still being used
            // to calculate the countdown timer and auto-switch to livestreams
            // when applicable
            <div
              className={classNames("d-none mt-3 p-2 pb-0 text-sm text-left", {
                "d-block": DEBUG,
              })}
              style={{
                backgroundColor: "rgba(0,0,0,0.3)",
                borderRadius: "6px",
              }}
            >
              <strong
                className="d-block mb-1"
                style={{
                  fontSize: "0.825rem",
                }}
              >
                Upcoming Live Service
              </strong>
              <div className="d-flex align-items-center">
                {upcomingService?.snippet?.thumbnails?.default?.url && (
                  <img
                    src={upcomingService?.snippet?.thumbnails?.default?.url}
                    className="mr-2 mb-0"
                  />
                )}
                <div>
                  <div className="font-bold text-balance">
                    {formatVideoTitle(upcomingService?.snippet?.title)}
                  </div>
                  <time>
                    {formatDateFromAPI(
                      upcomingService?.snippet?.scheduledStartTime,
                      locale
                    )}
                  </time>
                  <div>
                    <Countdown
                      date={upcomingService?.snippet?.scheduledStartTime}
                      onComplete={() => {
                        setUpcomingService(null)
                        setLatestSermon(upcomingService)
                        setIsLiveService(true)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-10 offset-1 col-md-6 offset-md-0 details">
          <div>
            {isLiveService && (
              <p className="title">{content?.live?.show?.live_now}</p>
            )}
            <h2>{formatVideoTitle(sermon?.snippet?.title)}</h2>
            <p className="date mb-3">
              {formatDateFromAPI(
                sermon?.snippet?.scheduledStartTime ||
                  sermon?.contentDetails?.videoPublishedAt ||
                  sermon?.snippet?.publishedAt,
                locale
              )}
            </p>
            <p
              className="description mb-3"
              dangerouslySetInnerHTML={{
                __html: formatVideoDescription(sermon?.snippet?.description),
              }}
            />
            {recentLiveService && env?.showSermonOnly && (
              <a
                className="text-nowrap"
                target="_blank"
                rel="noreferrer"
                href={
                  locale === "en"
                    ? `https://www.youtube.com/watch?v=${recentLiveService.id}`
                    : ""
                }
                data-turbolinks="false"
              >
                {content?.home?.latest_sermon?.view_full_service}&nbsp;
                <i className="fas fa-chevron-right align-middle"></i>
              </a>
            )}
            {/* <AdditionalLinks locale={locale} content={content} /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Player
