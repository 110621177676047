import Filters from './Filters/Filters';
import React from 'react';
import Results from './Results';
import classNames from 'classnames';
import { css } from '@emotion/css';
import useBreakpoint from '../../lib/useBreakpoint';
import { useRouteMatch, useLocation } from "react-router-dom"
import dateFormat from 'dateformat';

// eslint-disable-next-line no-unused-vars
function ServeWeek({ locale, campuses, events, occurrences }) {
  let location = useLocation()
  const query = new URLSearchParams(location.search)
  const path = useRouteMatch("/serveweek/:campus");
  const breakpoint = useBreakpoint();
  const [activeFilters, setActiveFilters] = React.useState([]);

  // Set initial filters if campus param is present
  React.useEffect(() => {
    if (path?.params?.campus) return setActiveFilters([{
      type: "campus",
      value: campuses.find(c => c.slug === path.params.campus).name_en
    }]);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const categories = React.useMemo(() => {
    return Array.from(
      new Set(
        occurrences.map((e) => e.event.tags?.['Event Category']).flat()
      )
    );
  }, [occurrences]);

  const opportunities = React.useMemo(() => {
    return occurrences.map((occ) => {
      return {
        ...occ,
        campus: campuses.find((c) => c.campus_abbreviation === `${occ?.event?.campuses?.[0]}` || c.campus_abbreviation === `${occ?.event.campuses?.[0]}-campus`),
      };
    });
  }, [occurrences]);

  const FILTER_DATA = [
    {
      title: 'Campus',
      key: 'campus',
      data: campuses,
      dataKey: 'name_en',
      match: (campus, result) => {
        const fullCampusData = campuses.find(c => c.name_en === campus) || false;
        return fullCampusData && result.event.campuses.includes(fullCampusData.campus_abbreviation);
      },
    },
    // {
    //   title: 'Category',
    //   data:  categories,
    //   dataKey: false,
    //   match: (category, result) => {
    //     return result.event.tags?.['Event Category']?.includes(category);
    //   },
    // },
    {
      title: 'Day',
      key: 'date',
      data: Array.from(
        new Set(
          occurrences.map((o) => dateFormat(o.starts_at, 'dddd, mmmm d'))
        )
      ).sort((a, b) => new Date(a) - new Date(b)),
      dataKey: false,
      match: (date, result) => {
        return dateFormat(result.starts_at, 'dddd, mmmm d') === date;
      },
    },
  ];

  // Filter all occurrences based on active filters
  const filteredResults = React.useMemo(() => {
    return opportunities.filter((opp) => {
      // For each filter grab all occurrences that match
      const allmatch = activeFilters.every((f) => {
        // Get the filters settings
        const origFilter = FILTER_DATA.find((fd) => fd.key === f.type);
        return origFilter?.match(f.value, opp);
      });

      return allmatch;
    });
  }, [opportunities, activeFilters]);

  query.get("debug") === "true" &&
    console.log({
      campuses,
      events,
      occurrences,
      opportunities,
      filteredResults,
    })
  
  return (
    <div className="d-flex flex-column flex-lg-row">
      <Filters
        filterData={FILTER_DATA}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        filteredResults={filteredResults}
        className={classNames(
          'pr-lg-4',
          css`
            min-width: ${breakpoint.isLgUp ? '30%' : 0};
          `
        )}
      />
      <Results
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        results={filteredResults}
        locale={locale}
      />
    </div>
  );
}

export default ServeWeek;
