import FilterGroupItem from './FilterGroupItem';
import React from 'react';
import useBreakpoint from '../../../lib/useBreakpoint';
function FilterGroups({
  filters,
  activeFilters,
  setActiveFilters,
  setFiltersVisible,
  filteredResults,
}) {
  const breakpoint = useBreakpoint();
  return (
    <div>
      {filters.map((filter, i) => (
        <FilterGroupItem
          key={i}
          filter={filter}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          setFiltersVisible={setFiltersVisible}
          filteredResults={filteredResults}
          isExpanded={breakpoint.isMdDown ? true : filter.key === "campus"}
        />
      ))}
    </div>
  );
}

export default FilterGroups;
