import React from 'react';
import axios from 'axios';
import classNames from 'classnames';

function DeleteModal({ profileId, content }) {
  const [step, setStep] = React.useState(1);
  const [confirmedDelete, setConfirmedDelete] = React.useState(false);
  const confirmInputRef = React.useRef();

  const handleModalClose = () => {
    $('#confirm-delete').modal('hide');
    setTimeout(() => {
      setStep(1);
    }, 1000);
  };

  const handleProfileDelete = async (e) => {
    e.preventDefault();
    e.target.disabled = true;
    const res = await axios.delete(`/profiles/${profileId}`, {
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      responseType: 'json',
      params: { 'authenticity_token': $('input[name="csrf-token"]').attr('content') }
    });
    
    if (res.status === 200) window.location.href = `/?success=${content.success_message}`;
  }

  if (!profileId) return null;

  return (
    <>
      <div className="mt-4">
        <a
          href="#"
          id="js-delete-profile"
          className="text-ketchup"
          onClick={(e) => {
            e.preventDefault();

            $('#confirm-delete').modal({
              backdrop: 'static',
              keyboard: false,
              show: true,
            });
          }}
        >
          {content.trigger}
        </a>
      </div>

      <div
        className="modal fade"
        id="confirm-delete"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="loadMeLabel"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body text-center">
              {step === 1 && (
                <div
                  id="modal-content-option-1"
                  className="modal-content-option"
                >
                  <div dangerouslySetInnerHTML={
                    { __html: content['step-1_html'] }
                  } />
                  <button
                    className="btn btn-sm btn-outline-secondary uppercase cancel-button mr-2"
                    onClick={handleModalClose}
                  >
                    {content.button_cancel}
                  </button>
                  <button className="btn btn-sm btn-danger uppercase" onClick={() => setStep(2)}>
                    {content.button_confirm}
                  </button>
                </div>
              )}

              {step === 2 && (
                <div
                  id="modal-content-option-2"
                  className="modal-content-option"
                >
                  <div dangerouslySetInnerHTML={
                    { __html: content['step-2_html'] }
                  } />
                  <p>
                    <input
                      type="text"
                      className="form-control tw-text-inherit"
                      id="delete-profile-confirm"
                      placeholder={content.delete_placeholder}
                      ref={confirmInputRef}
                      onChange={(e) => {
                        if (e.target.value.toLowerCase() === content.delete_placeholder.toLowerCase()) {
                          return setConfirmedDelete(true);
                        }

                        return setConfirmedDelete(false);
                      }}
                    />
                  </p>
                  <button onClick={handleModalClose} className="btn btn-sm btn-outline-secondary uppercase cancel-button mr-2">
                    {content.button_cancel}
                  </button>
                  <button
                    className={classNames("btn btn-sm uppercase", {
                      "btn-danger": confirmedDelete,
                      "btn-secondary tw-cursor-not-allowed": !confirmedDelete,
                    })}
                    disabled={!confirmedDelete}
                    onClick={handleProfileDelete}
                  >
                    {content.button_delete_profile}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteModal;
