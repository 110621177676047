import 'mapbox-gl/dist/mapbox-gl.css';

import {
  FlyToInterpolator,
  Layer,
  NavigationControl,
  Source,
} from 'react-map-gl';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import {
  clusterCountLayer,
  clusterLayer,
  loadIcons,
  mapAnimationStyle,
  scrollMapIntoView,
  unclusteredPointLayerIcon,
} from '../shared/globalMapSettings';

import GroupDetails from '../shared/Popups/GroupDetails';
import Loader from '../shared/Loader';
import Popup from '../shared/Popup';
import classNames from 'classnames';
import { css } from '@emotion/css';
import helpers from '../../lib/helpers';
import useBreakpoint from '../../lib/useBreakpoint';

const ReactMapGL = React.lazy(() => import('react-map-gl'));

const LocationsMap = ({ gatherings, currentGathering, locale }) => {
  const breakpoint = useBreakpoint();
  const mapRef = useRef();
  const [popupContent, setPopupContent] = useState(null);
  const [viewport, setViewport] = useState({
    longitude: -97.3024215500009,
    latitude: 35.64869695581544,
    zoom: breakpoint.isMdUp ? 9 : 3,
    maxzoom: 14,
    width: '100%',
    height: breakpoint.isMdUp ? '50vw' : '70vw',
    ...mapAnimationStyle,
  });

  // When the current breakpoint changes, update the map viewport
  useEffect(() => {
    setViewport({
      ...viewport,
      zoom: breakpoint.isMdUp ? 4 : 3,
    });
  }, [breakpoint]);

  const handleViewportChange = React.useCallback(
    (newViewport) => setViewport(newViewport),
    []
  );

  // If a specific gathering has been specified, update state
  useEffect(() => {
    if (!currentGathering || !mapRef?.current) return;
    const gathering = gatherings.find((g) => g.id == currentGathering) || false;

    if (gathering) {
      handleViewportChange({
        ...viewport,
        longitude: gathering.longitude,
        latitude: gathering.latitude,
      });

      setPopupContent(gathering);
      scrollMapIntoView(mapRef);
    }
  }, [currentGathering, gatherings, mapRef]);

  const gatheringsSourceData = React.useMemo(
    () => helpers.prepareSourceData(gatherings, 'latitude', 'longitude'),
    [gatherings]
  );

  const sources = React.useMemo(
    () => (
      <>
        <Source
          id="gatherings"
          type="geojson"
          data={gatheringsSourceData}
          cluster={true}
          clusterMaxZoom={15}
          clusterRadius={50}
        >
          <Layer
            {...clusterLayer({
              theme: 'dark',
              id: 'gatherings',
              source: 'gatherings',
              background: 'rgba(113, 191, 231, 0.5)',
            })}
          />
          <Layer
            {...clusterCountLayer({
              theme: 'dark',
              id: 'gatherings',
              source: 'gatherings',
            })}
          />
          <Layer
            {...unclusteredPointLayerIcon({
              id: 'gatherings',
              source: 'gatherings',
            })}
          />
        </Source>
      </>
    ),
    [gatheringsSourceData]
  );

  return (
    <div className="position-relative">
      <div className="p-3 p-lg-0">
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <ReactMapGL
          ref={mapRef}
          attributionControl={false}
          scrollZoom={true}
          {...viewport}
          width="100%"
          height="50vw"
          mapStyle="mapbox://styles/mapbox/dark-v9"
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={process.env.MAPBOX_TOKEN}
          interactiveLayerIds={[
            'gatherings-clusters',
            'gatherings-unclustered-point-icon',
          ]}
          onClick={(event) => {
            const feature = event?.features?.[0];
            if (!feature) return event;
            const clusterId = feature?.properties?.cluster_id || false;
            const mapboxSource = mapRef.current
              .getMap()
              .getSource(feature.source);

            // we've clicked on an single marker
            if (!clusterId) {
              setViewport({
                ...viewport,
                transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
                transitionDuration: 'auto',
                longitude: feature.geometry.coordinates[0],
                latitude: feature.geometry.coordinates[1],
              });

              setPopupContent(
                helpers.standardizeLatLng(JSON.parse(feature?.properties?.data))
              );

              scrollMapIntoView(mapRef);
            } else {
              // We've clicked on a cluster
              mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) return console.log(err);

                setViewport({
                  ...viewport,
                  longitude: feature.geometry.coordinates[0],
                  latitude: feature.geometry.coordinates[1],
                  transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
                  transitionDuration: 'auto',
                  zoom,
                });
              });
            }
          }}
          onLoad={(e) => {
            loadIcons(e);
          }}
          className={css`
            .mapboxgl-ctrl-logo {
              display: none;
            }
          `}
        >
          {sources}

          {popupContent && breakpoint.isMdUp && (
            <Popup
              anchor="top"
              longitude={popupContent.longitude}
              latitude={popupContent.latitude}
              closeOnClick={false}
              onClose={setPopupContent}
              variant="dark"
              offsetTop={5}
            >
              <GroupDetails group={popupContent} locale={locale} />
            </Popup>
          )}


          <NavigationControl
            showCompass={false}
            className={css`
              position: absolute;
              bottom: 1rem;
              right: 1rem;
              max-width: 29px;
              z-index: 10000;
            `}
          />
        </ReactMapGL>
      </Suspense>

      {popupContent && breakpoint.isMdDown && (
        <div className="p-3 position-relative">
          <button
            className={classNames(
              'mapboxgl-popup-close-button',
              css`
                color: white;
                right: 0;
                top: 1rem;
                font-size: 1.5rem;
                width: auto;
              `
            )}
            type="button"
            onClick={() => setPopupContent(null)}
          >
            ×
          </button>
          <GroupDetails group={popupContent} locale={locale} />
        </div>
      )}

      {gatherings.length < 1 && (
        <div
          className={classNames(
            'position-absolute d-flex align-items-center w-100 h-100',
            css`
              top: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.5);
            `
          )}
        >
          <Loader />
        </div>
      )}
    </div>
  );
};

export default React.memo(LocationsMap);
