import React from "react"
import { useContent } from "../context/ContentProvider"

function CampusPastor() {
  const { content } = useContent()

  return (
    <section
      id="campus-pastor"
      className="christmas-2023__campusPastor py-5 pt-lg-8 pb-lg-6"
    >
      <div className="container p-relative">
        <h3
          className="christmas-2023__campusPastor__title text-gold font-chronicle mb-2"
          dangerouslySetInnerHTML={{
            __html: content?.campus_pastor?.title_html,
          }}
        />
        <time
          className="christmas-2023__campusPastor__date d-block mb-3"
          dangerouslySetInnerHTML={{ __html: content?.campus_pastor?.date }}
        />
        <div
          className="christmas-2023__campusPastor__description mx-auto"
          dangerouslySetInnerHTML={{
            __html: content?.campus_pastor?.description_html,
          }}
        />
      </div>
    </section>
  )
}

export default CampusPastor
