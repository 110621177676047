import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';

import {
  FlyToInterpolator,
  GeolocateControl,
  Layer,
  Marker,
  NavigationControl,
  Source,
} from 'react-map-gl';
import {
  Icon,
  clusterCountLayer,
  clusterLayer,
  layerLabel,
  loadIcons,
  mapAnimationStyle,
  scrollMapIntoView,
  unclusteredPointLayerIcon,
} from '../shared/globalMapSettings';
/* jshint ignore:start */
import React, { Suspense, useCallback, useRef, useState } from 'react';

import CampusDetails from '../shared/Popups/CampusDetails';
import Filters from './Filters';
import Geocoder from 'react-map-gl-geocoder';
import GroupDetails from '../shared/Popups/GroupDetails';
import Loader from '../shared/Loader';
import MapGL from 'react-map-gl';
import Popup from '../shared/Popup';
import Search from '../shared/MapSearch';
import classNames from 'classnames';
import { css } from '@emotion/css';
import helpers from '../../lib/helpers';
import mediaQueries from '../../lib/mediaQueries';
import useBreakpoint from '../../lib/useBreakpoint';

const LocationsMap = ({
  campuses,
  gatherings,
  adminOffices,
  prisonCampuses,
  locale,
}) => {
  const breakpoint = useBreakpoint();
  const mapRef = useRef();
  const geocoderContainerRef = useRef();
  const [popupContent, setPopupContent] = useState(null);
  const [filters, setFilters] = useState({
    showGatherings: false,
    showAdminOffices: true,
    showPrisonCampuses: false,
  });
  const [viewport, setViewport] = useState({
    longitude: -97.03299293905454,
    latitude: 33.03436215719472,
    zoom: breakpoint.isMdUp ? 9: 8,
    maxzoom: 14,
    width: '100%',
    height: '100%',
    aspectRatio: breakpoint.isMdUp ? "1.56" : "1.06",
    ...mapAnimationStyle,
  });

  const escapeKeyEvent = new KeyboardEvent('keydown', {
    key: 'Escape',
    code: 'Escape',
    keyCode: 27,
    which: 27,
    bubbles: true, // Allow event to bubble up the DOM
    cancelable: true, // Allow event to be canceled
  });

  const handleViewportChange = (newViewport) => {
    setViewport(newViewport);
    const geocoderInput = document.querySelector('.mapboxgl-ctrl-geocoder input');
    if (geocoderInput) {
      geocoderInput.value = '';
    }
    geocoderInput.dispatchEvent(escapeKeyEvent);
  };

  const searchByCampus = useCallback((query) => {
    const matchingFeatures = [];
    campusSourceData.features.map(c => {
      if (c.properties.title.toLowerCase().search(query.toLowerCase()) !== -1 || c.properties.data.city.toLowerCase().search(query.toLowerCase()) !== -1 || c.properties.data.abbreviation === query) {
        matchingFeatures.push({
          place_name: c.properties.title,
          place_type: ['poi'],
          center: c.geometry.coordinates
        })
      }
    })

    prisonCampusesSourceData.features.map(c => {
      if (c.properties.title.toLowerCase().search(query.toLowerCase()) !== -1 || c.properties.data.city.toLowerCase().search(query.toLowerCase()) !== -1 || c.properties.data.abbreviation === query) {
        matchingFeatures.push({
          place_name: c.properties.title,
          place_type: ['poi'],
          center: c.geometry.coordinates
        })
      }
    })

    gatheringsSourceData.features.map(c => {
      if (c.properties.title.toLowerCase().search(query.toLowerCase()) !== -1 || c.properties.data.abbreviation === query) {
        matchingFeatures.push({
          place_name: c.properties.title,
          place_type: ['poi'],
          center: c.geometry.coordinates
        })
      }
    })

    adminOfficePins.map(c => {
      if (c.props?.title?.toLowerCase()?.search(query.toLowerCase()) !== -1 || c.props?.data?.abbreviation === query) {
        matchingFeatures.push({
          place_name: c.props.title,
          place_type: ['poi'],
          center: [c.props.longitude, c.props.latitude]
        })
      }
    })

    return matchingFeatures;
  });

  const campusSourceData = React.useMemo(
    () => helpers.prepareSourceData(campuses, 'lat', 'lng'),
    [campuses]
  );

  const prisonCampusesSourceData = React.useMemo(
    () =>
      helpers.prepareSourceData(
        filters.showPrisonCampuses ? prisonCampuses : [],
        'lat',
        'lng'
      ),
    [prisonCampuses, filters]
  );

  const gatheringsSourceData = React.useMemo(
    () =>
      helpers.prepareSourceData(
        filters.showGatherings ? gatherings : [],
        'latitude',
        'longitude'
      ),
    [gatherings, filters]
  );

  const adminOfficePins = React.useMemo(() => {
    return (
      filters.showAdminOffices &&
      adminOffices.map((o, i) => {
        adminOffices[i].type = 'adminOffice';
        return (
          <Marker key={i} longitude={o.lng} latitude={o.lat} title={o.name_en || o.name_es}>
            <Icon
              size={20}
              fill="var(--green)"
              onClick={() => setPopupContent(helpers.standardizeLatLng(o))}
              transform
            />
          </Marker>
        );
      })
    );
  }, [adminOffices, filters]);

  const popupContentComponent = React.useMemo(
    () => (
      <>
        {popupContent && (
          <>
            {popupContent?.type === 'Gateway Gatherings' && (
              <GroupDetails group={popupContent} locale={locale} />
            )}
            {(popupContent?.locationType === 'campus' || popupContent?.location_type === 'prison_campus' ||
              popupContent?.locationType === 'gathering_plus') && (
              <CampusDetails campus={popupContent} locale={locale} />
            )}
            {popupContent?.location_type === 'admin_office' && (
              <div
                className={classNames(
                  'd-flex flex-column justify-items-center',
                  css`
                    font-size: 0.8rem;

                    ${mediaQueries.lg} {
                      width: 350px;
                    }

                    h3 {
                      font-size: 1.2rem;
                    }

                    strong {
                      font-weight: 600;
                    }

                    .btn {
                      font-size: inherit;
                    }

                    .pill {
                      display: inline-block;
                      background-color: rgba(255, 255, 255, 0.2);
                      padding: 3px 11px;
                      border-radius: 12px;
                      font-weight: 600;
                      color: inherit;
                    }
                  `
                )}
              >
                <h3 className={classNames('mb-2')}>
                  {helpers.translateField(popupContent, 'name', locale)}
                </h3>
                <div className="mb-2">
                  {popupContent?.address_line_one && (
                    <address className="mb-0">
                      <span>
                        {popupContent.address_line_one}
                        <br />
                      </span>
                      {popupContent.address_line_two && (
                        <span>
                          {popupContent.address_line_two}
                          <br />
                        </span>
                      )}
                      {popupContent.city}, {popupContent.state}{' '}
                      {popupContent.zip}
                    </address>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </>
    ),
    [popupContent, locale]
  );

  return (
    <div className="position-relative">
      <div className="p-3 p-lg-0">
        <Filters
          className={css`
            top: 1.5rem;
            right: 1.5rem;

            ${mediaQueries.md} {
              top: unset;
              right: 1.5rem;
            }
          `}
          filters={filters}
          setFilters={setFilters}
        />
        <Search
          ref={geocoderContainerRef}
          className={classNames(
            'mb-2 mb-lg-0',
            css`
              top: 1.5rem;
              left: 1.5rem;

              ${mediaQueries.xl} {
                left: 50%;
                transform: translateX(-50%);
              }
            `
          )}
        />
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <div style={{aspectRatio: breakpoint.isMdUp ? "1.56" : "1.06"}}>
          <MapGL
            ref={mapRef}
            attributionControl={false}
            scrollZoom={true}
            {...viewport}
            width="100%"
            height="100%"
            mapStyle="mapbox://styles/mapbox/light-v9"
            onViewportChange={handleViewportChange}
            // eslint-disable-next-line no-undef
            mapboxApiAccessToken={process?.env?.MAPBOX_TOKEN}
            interactiveLayerIds={[
              'gatherings-clusters',
              'campuses-clusters',
              'prisonCampuses-clusters',
              'gatherings-unclustered-point-icon',
              'campuses-unclustered-point-icon',
              'prisonCampuses-unclustered-point-icon',
            ]}
            onClick={(event) => {
              const feature = event?.features?.[0];
              if (!feature) return event;
              const clusterId = feature?.properties?.cluster_id || false;
              const mapboxSource = mapRef.current
                .getMap()
                .getSource(feature.source);

              // we've clicked on an single marker
              if (!clusterId) {
                setViewport({
                  ...viewport,
                  transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
                  transitionDuration: 'auto',
                  longitude: feature.geometry.coordinates[0],
                  latitude: feature.geometry.coordinates[1],
                });

                setPopupContent(
                  helpers.standardizeLatLng(JSON.parse(feature?.properties?.data))
                );
                scrollMapIntoView(mapRef);
              } else {
                // We've clicked on a cluster
                mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
                  if (err) return console.log(err);

                  setViewport({
                    ...viewport,
                    longitude: feature.geometry.coordinates[0],
                    latitude: feature.geometry.coordinates[1],
                    transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
                    transitionDuration: 'auto',
                    zoom,
                  });
                });
              }
            }}
            onLoad={(e) => {
              loadIcons(e);
            }}
            className={css`
              .mapboxgl-ctrl-logo {
                display: none;
              }
            `}
          >
            <Geocoder
              containerRef={geocoderContainerRef}
              mapRef={mapRef}
              countries="us"
              trackProximity={true}
              onViewportChange={setViewport}
              // eslint-disable-next-line no-undef
              mapboxApiAccessToken={process.env.MAPBOX_TOKEN}
              marker={false}
              placeholder="Search by location, ZIP or campus"
              localGeocoder={searchByCampus}
              autoComplete={false}
              clearOnBlur={true}
              clearAndBlurOnEsc={true}
            />
            <Source
              id="gatherings"
              type="geojson"
              data={gatheringsSourceData}
              cluster={true}
              clusterMaxZoom={13}
              clusterRadius={50}
            >
              <Layer
                {...clusterLayer({
                  theme: 'light',
                  id: 'gatherings',
                  source: 'gatherings',
                  background: 'rgba(113, 191, 231, 0.5)',
                })}
              />
              <Layer
                {...clusterCountLayer({
                  theme: 'light',
                  id: 'gatherings',
                  source: 'gatherings',
                })}
              />
              <Layer
                {...unclusteredPointLayerIcon({
                  id: 'gatherings',
                  source: 'gatherings',
                })}
              />
            </Source>
            <Source
              id="campuses"
              type="geojson"
              data={campusSourceData}
              cluster={true}
              clusterMaxZoom={13}
              clusterRadius={50}
            >
              <Layer
                {...clusterLayer({
                  theme: 'light',
                  id: 'campuses',
                  source: 'campuses',
                  background: 'rgba(255, 0, 0, 1)',
                })}
              />
              <Layer
                {...clusterCountLayer({
                  theme: 'light',
                  id: 'campuses',
                  source: 'campuses',
                })}
              />
              <Layer
                {...layerLabel({
                  theme: 'light',
                  id: 'campuses',
                  source: 'campuses',
                })}
              />
              <Layer
                {...unclusteredPointLayerIcon({
                  id: 'campuses',
                  source: 'campuses',
                  icon: 'campusIcon',
                  iconSize: 1.1,
                })}
              />
            </Source>
            <Source
              id="prisonCampuses"
              type="geojson"
              data={prisonCampusesSourceData}
              cluster={true}
              clusterMaxZoom={13}
              clusterRadius={50}
            >
              <Layer
                {...clusterLayer({
                  theme: 'light',
                  id: 'prisonCampuses',
                  source: 'prisonCampuses',
                  background: 'rgba(126, 147, 172, 0.5)',
                })}
              />
              <Layer
                {...clusterCountLayer({
                  theme: 'light',
                  id: 'prisonCampuses',
                  source: 'prisonCampuses',
                })}
              />
              <Layer
                {...unclusteredPointLayerIcon({
                  id: 'prisonCampuses',
                  source: 'prisonCampuses',
                  icon: 'prisonCampusIcon',
                })}
              />
            </Source>
            {adminOfficePins}
            {popupContent && breakpoint.isMdUp && (
              <Popup
                anchor="top"
                longitude={popupContent.longitude}
                latitude={popupContent.latitude}
                closeOnClick={false}
                onClose={setPopupContent}
                variant="light"
                offsetTop={5}
              >
                {popupContentComponent}
              </Popup>
            )}

            <GeolocateControl
              positionOptions={{ enableHighAccuracy: false }}
              onViewportChange={setViewport}
              trackUserLocation={true}
              className={css`
                position: absolute;
                bottom: 80px;
                right: 1rem;
                max-width: 29px;
                z-index: 10000;
              `}
            />

            <NavigationControl
              showCompass={false}
              className={css`
                position: absolute;
                bottom: 1rem;
                right: 1rem;
                max-width: 29px;
                z-index: 10000;
              `}
            />
          </MapGL>
        </div>
      </Suspense>
      {popupContent && breakpoint.isMdDown && (
        <div className="p-3 position-relative">
          <button
            className={classNames(
              'mapboxgl-popup-close-button',
              css`
                color: white;
                right: 0;
                top: 1rem;
                font-size: 1.5rem;
                width: auto;
              `
            )}
            type="button"
            onClick={() => setPopupContent(null)}
          >
            ×
          </button>
          {popupContentComponent}
        </div>
      )}

      {campuses.length < 1 && (
        <div
          className={classNames(
            'position-absolute d-flex align-items-center w-100 h-100',
            css`
              top: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.5);
            `
          )}
        >
          <Loader />
        </div>
      )}
    </div>
  );
};

export default React.memo(LocationsMap);
/* jshint ignore:end */