import React, { useState } from "react"

export const SettingsContext = React.createContext({
  youTubeIds: {},
  env: {},
})

export const SettingsProvider = ({ children, youtube_ids, env }) => {
  const [youTubeIds, setYouTubeIds] = useState(youtube_ids)

  const state = React.useMemo(
    () => ({ youTubeIds, setYouTubeIds, env }),
    [youTubeIds, setYouTubeIds, env]
  )

  React.useEffect(() => {
    setYouTubeIds(youtube_ids)
  }, [youtube_ids])

  return (
    <SettingsContext.Provider value={state}>
      {children}
    </SettingsContext.Provider>
  )
}

export const useSettings = () => {
  const context = React.useContext(SettingsContext)
  return { ...context }
}
