import React from 'react'

function AdditionalLinks({ locale, content }) {
  return (
    <div className="sermon-links">
      <a
        data-turbolinks="false"
        className="mr-4 text-nowrap"
        href={locale === 'en' ? `/es?ic=true` : `/?ic=true`}
      >
        {locale === 'en' ? 'Español' : 'English'}&nbsp;
        <i className="fas fa-chevron-right align-middle"></i>
      </a>
      <a className="text-nowrap" href={locale === 'en' ? `/kids-experience?ic=true` : `/es/kids-experience?ic=true`}
         data-turbolinks="false">
        {content?.home?.latest_sermon?.kids_experience}&nbsp;
        <i className="fas fa-chevron-right align-middle"></i>
      </a>
    </div>
  )
}

export default AdditionalLinks
