import React from "react"

function Notification({ locale, content, hidden = false, onHide = () => {} }) { // eslint-disable-line no-unused-vars
  if (hidden)
    return (
      <svg
        key="live-service-notification-notification-hidden"
        width="103"
        height="103"
        viewBox="0 0 103 103"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="d-block position-fixed live-service-notification__icon tw-cursor-pointer"
        onClick={() => onHide(false)}
      >
        <g filter="url(#filter0_d_590_729)">
          <rect x="24" y="24" width="55" height="55" rx="27.5" fill="#EBECEC" />
          <path
            d="M41.8428 52.3098V46.1711H43.3223V51.0178H45.6787V52.3098H41.8428ZM46.9589 52.3098V46.1711H48.4383V52.3098H46.9589ZM53.3873 52.3098H51.917L49.6127 46.1711H51.2639L52.6744 50.5232H52.7081L54.1094 46.1711H55.7345L53.3873 52.3098ZM56.9089 52.3098V46.1711H61.0024V47.4199H58.3271V48.5821H60.8552V49.7706H58.3271V51.0456H61.1572V52.3114H56.9089V52.3098ZM67 42.125V56.1875C67 57.9138 65.6125 59.3125 63.9 59.3125H39.1C37.3875 59.3125 36 57.9138 36 56.1875V42.125C36 40.3987 37.3875 39 39.1 39H63.9C65.6095 39 67 40.3987 67 42.125ZM64.6758 42.125C64.6758 41.6938 64.3278 41.343 63.9 41.343H39.1C38.6723 41.343 38.3242 41.6938 38.3242 42.125V56.1875C38.3242 56.6187 38.6723 56.968 39.1 56.968H63.9C64.3278 56.968 64.6758 56.6171 64.6758 56.1875V42.125ZM62.3515 62.8285C62.3515 63.4761 61.8303 64 61.1879 64H41.8137C41.1697 64 40.65 63.4776 40.65 62.8285C40.65 62.1794 41.1682 61.657 41.8137 61.657H61.1879C61.8318 61.657 62.3515 62.1794 62.3515 62.8285Z"
            fill="url(#paint0_linear_590_729)"
          />
          <rect
            x="24.5"
            y="24.5"
            width="54"
            height="54"
            rx="27"
            stroke="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_590_729"
            x="0"
            y="0"
            width="103"
            height="103"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="12" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0.5 0 0 0 0 1 0 0 0 0.5 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_590_729"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_590_729"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_590_729"
            x1="42"
            y1="67"
            x2="67"
            y2="39"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#002E55" />
            <stop offset="1" stopColor="#009DF5" />
          </linearGradient>
        </defs>
      </svg>
    )
  // Show the notification
  return (
    <div
      key="live-service-notification-notification"
      className="live-service-notification text-white position-fixed"
    >
      <button
        className="live-service-notification__close text-white position-absolute"
        onClick={() => onHide(true)}
      >
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_1027_514)">
            <path
              d="M17.3203 5.23828C17.8789 4.72266 17.8789 3.82031 17.3203 3.30469C16.8047 2.74609 15.9023 2.74609 15.3867 3.30469L10.875 7.81641L6.32031 3.30469C5.80469 2.74609 4.90234 2.74609 4.38672 3.30469C3.82812 3.82031 3.82812 4.72266 4.38672 5.23828L8.89844 9.75L4.38672 14.3047C3.82812 14.8203 3.82812 15.7227 4.38672 16.2383C4.90234 16.7969 5.80469 16.7969 6.32031 16.2383L10.875 11.7266L15.3867 16.2383C15.9023 16.7969 16.8047 16.7969 17.3203 16.2383C17.8789 15.7227 17.8789 14.8203 17.3203 14.3047L12.8086 9.75L17.3203 5.23828Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_1027_514"
              x="0.828125"
              y="0.746094"
              width="20.0508"
              height="20.0508"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1027_514"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1027_514"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </button>
      <h3 className="live-service-notification__title mb-2">{content.title}</h3>
      <p className="live-service-notification__description mb-2">
        {content.description}
      </p>
      {content?.cta?.href && (
        <a
          href={content.cta.href}
          target={content?.cta?.target || "_self"}
          className="live-service-notification__cta"
        >
          {content.cta.title}
        </a>
      )}
    </div>
  )
}

export default Notification
