export const isWeekend = (override) => {
  if (override) return override
  // Calculate if it's Saturday or Sunday in CST accounting for DST
  const nowUTC = new Date()
  const cstOffset = isDST(nowUTC) ? -5 : -6 // UTC-5 for CDT, UTC-6 for CST
  const nowCST = new Date(nowUTC.setHours(nowUTC.getHours() + cstOffset))
  return nowCST.getDay() === 6 || nowCST.getDay() === 0
}

export const numDaysBetween = function (date1, date2) {
  const diff = Math.abs(date1.getTime() - date2.getTime())
  return diff / (1000 * 60 * 60 * 24)
}

export const hasServiceStarted = (dateString) => {
  const today = new Date()
  const inputDate = new Date(dateString)
  return inputDate <= today
}

export const dateToInteger = (date) => {
  return new Date(date).getTime()
}

const isDST = (date) => {
  // DST starts on the second Sunday in March
  const startDST = new Date(
    date.getFullYear(),
    2,
    14 - (new Date(date.getFullYear(), 2, 1).getDay() || 7)
  )
  // DST ends on the first Sunday in November
  const endDST = new Date(
    date.getFullYear(),
    10,
    7 - (new Date(date.getFullYear(), 10, 1).getDay() || 7)
  )

  return date >= startDST && date < endDST
}

export const isFriday = () => {
  const nowUTC = new Date()
  const cstOffset = isDST(nowUTC) ? -5 : -6 // UTC-5 for CDT, UTC-6 for CST
  const nowCST = new Date(nowUTC.setHours(nowUTC.getHours() + cstOffset))

  return nowCST.getDay() === 5 // 5 represents Friday
}

export const shouldShowSermonOnlyService = ({
  showSermonOnly,
  sermonOnlyDay,
  sermonOnlyTime,
}) => {
  if (!showSermonOnly) return false

  const now = new Date()
  const nowCST = new Date(
    now.toLocaleString("en-US", { timeZone: "America/Chicago" })
  )

  const day = nowCST.getDay()
  const hour = nowCST.getHours()
  const min = nowCST.getMinutes()

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  // Get the index of the current day

  const minSermonOnlyDay = daysOfWeek.indexOf(sermonOnlyDay) || 1
  const [minHour, minMinute] = sermonOnlyTime.split(":")

  // Check if it's Monday after 10:30 AM CST OR Tuesday through Friday
  if (
    (day === minSermonOnlyDay &&
      (hour > parseInt(minHour) ||
        (hour === parseInt(minHour) && min >= parseInt(minMinute)))) ||
    (day >= minSermonOnlyDay + 1 && day <= 5)
  ) {
    return true
  }

  return false
}

export const wasPublishedInLastXDays = (video, x) => {
  const now = new Date()
  const publishedAt = new Date(video.snippet.publishedAt)
  const xDaysAgo = new Date(
    now.toLocaleString("en-US", { timeZone: "America/Chicago" })
  )
  xDaysAgo.setDate(xDaysAgo.getDate() - x)

  return publishedAt >= xDaysAgo
}
