import React from "react"
import YouTubePlayButton from "../../../shared/YouTubePlayButton"
import YouTube from "react-youtube"
import { formatVideoTitle } from "../../../yt_live_player/util/youtube"
import classNames from "classnames"
import { css } from "@emotion/css"

const opts = {
  height: "auto",
  width: "100%",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
    controls: 1,
    rel: 0,
  },
}

// A wrapper for the YouTube player that shows a play button and then plays the video when clicked
function VideoPlayer({ video }) {
  const [showPlayer, setShowPlayer] = React.useState(false)
  const [videoPlayer, setVideoPlayer] = React.useState(null)

  const { videoTitle } = React.useMemo(() => {
    if (!video) return {}
    return {
      videoTitle: formatVideoTitle(video?.snippet?.localized?.title),
    }
  }, [video])

  // When the video changes we need to reset the state of this component
  React.useEffect(() => {
    if (!video) return
    setVideoPlayer(null)
    setShowPlayer(false)
  }, [video])

  // Sets up a handler for the YouTube player so we can control the playback
  const onPlayerReady = React.useCallback(
    (event) => {
      setVideoPlayer(event?.target)
    },
    [video]
  )

  return (
    <>
      <YouTube
        loading="eager"
        videoId={video?.id}
        opts={opts}
        onReady={onPlayerReady}
        title={videoTitle}
        className={classNames(
          "w-full",
          {
            "d-none": !showPlayer,
            "d-block": showPlayer,
          },
          css`
            aspect-ratio: 16/9;

            & iframe {
              width: 100%;
              height: 100%;
            }
          `
        )}
      />
      <div
        className={classNames(
          "w-full video-click-to-play flex-col align-items-center justify-content-center p-2",
          {
            "d-none": showPlayer,
            "d-flex": !showPlayer,
          }
        )}
        onClick={() => {
          setShowPlayer(true)
          videoPlayer?.playVideo()
        }}
        style={{
          backgroundImage: `url(${
            video?.snippet?.thumbnails?.maxres?.url ||
            video?.snippet?.thumbnails?.standard?.url ||
            video?.snippet?.thumbnails?.high?.url
          })`,
        }}
      >
        <YouTubePlayButton className="play-button" />
      </div>
    </>
  )
}

export default VideoPlayer
