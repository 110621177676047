import React from 'react';
import { Marker } from 'react-map-gl';
import classNames from 'classnames';
import { css } from '@emotion/css';

const SIZE = 50;
function Pins({ data, onClick, viewport, popupVisible }) {
  return data.map((gathering, index) => (
    <Marker
      key={`marker-${index}`}
      longitude={gathering.longitude}
      latitude={gathering.latitude}
      className={css`
        &:hover {
          z-index: 100000;
        }
      `}
    >
      <div
        className={css`
          position: relative;
          transform: translate(-50%, -50%);

          &:hover {
            z-index: 100000;

            .hover {
              opacity: ${!popupVisible || popupVisible?.id != gathering.id
                ? '1'
                : '0'};
            }
          }

          .hover {
            opacity: ${!popupVisible && viewport.zoom > 6 ? '1' : '0'};
          }
        `}
      >
        <img
          src="/assets/icons/group-icon.png"
          height={SIZE}
          onClick={() => onClick(gathering)}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          className={css`
            margin-bottom: 0;
            cursor: pointer;
            width: auto;
          `}
        />
        <span
          className={classNames(
            'hover',
            css`
              background-color: black;
              position: absolute;
              bottom: 0;
              text-align: center;
              left: 50%;
              transform: translate(-50%, 125%);
              white-space: nowrap;
              font-size: 14px;
              padding: 3px 9px;
              border-radius: 5px;
              pointer-events: none;
              font-weight: 600;
            `
          )}
        >
          {gathering.name}
        </span>
      </div>
    </Marker>
  ));
}

export default React.memo(Pins);
