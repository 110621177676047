import React from "react"
import { ContentProvider } from "./context/ContentProvider"
import Christmas2023 from "./views/Christmas2023"

function App({ locale, campuses, content }) {
  return (
    <ContentProvider>
      <Christmas2023 locale={locale} campuses={campuses} content={content} />
    </ContentProvider>
  )
}

export default App
