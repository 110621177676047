import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';

import React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import mediaQueries from '../../lib/mediaQueries';
import useComponentVisible from '../../hooks/useComponentVisible';

// eslint-disable-next-line react/display-name, no-unused-vars
const Search = React.forwardRef(({ children, className }, ref) => {
  const {
    ref: visRef,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  return (
    <div
      ref={visRef}
      onClick={() => setIsComponentVisible(true)}
      className={classNames(
        className,
        css`
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          z-index: 100;
          background: white;
          border-radius: 10px;
          box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
          border: 1px solid rgba(0, 0, 0, 0.2);

          ${mediaQueries.lg} {
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
            max-width: 400px;
            position: absolute;
          }

          .search {
            width: 100%;
            display: flex;
            justify-content: center;

            .mapboxgl-ctrl-geocoder {
              border-radius: 10px;
            }

            .mapboxgl-ctrl-geocoder,
            .mapboxgl-ctrl-geocoder .suggestions {
              box-shadow: none;
              width: 100%;
              max-width: none;
            }

            .mapboxgl-ctrl-geocoder .suggestions {
              top: calc(100% + 40px);
            }

            .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > *,
            .mapboxgl-ctrl-geocoder--icon {
              top: 50%;
              transform: translateY(-50%);

              &-search {
                left: 1rem;
              }
            }

            .mapboxgl-ctrl-geocoder--input {
              font-size: 1.2rem;
              height: 50px;
              padding-left: 2.5rem;

              &:focus {
                outline: none;
              }
            }

            .mapboxgl-ctrl-geocoder--icon-close {
              margin-top: 0;
            }
          }
        `
      )}
    >
      <div className="search" ref={ref}></div>
      {isComponentVisible && (
        <div
          className={classNames(
            'use-location w-100 py-2 text-center',
            css`
              color: var(--colors-gray);
              border-top: 1px solid #eaeaea;
              cursor: pointer;
            `
          )}
          onClick={() => {
            $('.mapboxgl-ctrl-geolocate').trigger('click');
          }}
        >
          <i className="fas fa-location-arrow mr-1"></i>Use my current location
        </div>
      )}
    </div>
  );
});

export default Search;
