import React from 'react'
import LoaderCard from '../Loaders/LoaderCard'
import {
  getPlaylist,
  formatVideoTitle,
  formatVideoDescription,
  createYouTubeURL,
  formatDateFromAPI,
} from '../../../yt_live_player/util/youtube'
import classNames from 'classnames'

function PlaylistCard({ id, locale = 'en', className }) {
  const [playlistItem, setPlaylistItem] = React.useState(null)

  React.useEffect(() => {
    const loadPlaylistItem = async () => {
      const response = await getPlaylist(id)
      if (response && !response?.error && response?.items?.[0])
        return setPlaylistItem(response?.items?.[0])

      return setPlaylistItem(false)
    }

    loadPlaylistItem()
  }, [id])

  if (!id || playlistItem === false) return null

  if (!playlistItem) return <><LoaderCard /></>

  return (
    <div className={classNames("sermon-card-component", className)}>
      <a
        data-turbolinks="false"
        className="sermon-card"
        href={createYouTubeURL({
          type: 'playlist',
          id: playlistItem?.id,
          slug: playlistItem?.snippet?.title.split('|')[0],
          locale
        })}
      >
          <img
            className="youtube-thumbnail w-full h-auto"
            src={playlistItem?.snippet?.thumbnails?.high?.url}
            alt={formatVideoTitle(playlistItem.snippet.title)}
          />
        <h2 className="h4 text-balance">{formatVideoTitle(playlistItem.snippet.title)}</h2>
        <div className="date">
          {formatDateFromAPI(
            playlistItem?.contentDetails?.videoPublishedAt ||
              playlistItem?.snippet?.publishedAt,
            locale
          )}
        </div>
        {/* <div className="speaker"></div> */}
        <p className="description mt-1 mb-4">
          {formatVideoDescription(playlistItem?.snippet?.description)}
        </p>
      </a>
    </div>
  )
}

export default PlaylistCard

/**
 * {
    "kind": "youtube#playlist",
    "etag": "Mb2t7v6SKBJ8SB8QiRMwcRnCp_0",
    "id": "PLFgcIA8Y9FMD2ecXA8F2_NH2IsbOnOdYG",
    "snippet": {
        "publishedAt": "2023-01-10T17:29:37Z",
        "channelId": "UCNEjnooRRpudDeiHKKqWVNA",
        "title": "First Conference 2023",
        "description": "",
        "thumbnails": {
            "default": {
                "url": "https://i.ytimg.com/vi/LAqbloTbRmo/default.jpg",
                "width": 120,
                "height": 90
            },
            "medium": {
                "url": "https://i.ytimg.com/vi/LAqbloTbRmo/mqdefault.jpg",
                "width": 320,
                "height": 180
            },
            "high": {
                "url": "https://i.ytimg.com/vi/LAqbloTbRmo/hqdefault.jpg",
                "width": 480,
                "height": 360
            },
            "standard": {
                "url": "https://i.ytimg.com/vi/LAqbloTbRmo/sddefault.jpg",
                "width": 640,
                "height": 480
            },
            "maxres": {
                "url": "https://i.ytimg.com/vi/LAqbloTbRmo/maxresdefault.jpg",
                "width": 1280,
                "height": 720
            }
        },
        "channelTitle": "gatewaychurchtv",
        "localized": {
            "title": "First Conference 2023",
            "description": ""
        }
    },
    "status": {
        "privacyStatus": "public"
    },
    "contentDetails": {
        "itemCount": 5
    },
    "player": {
        "embedHtml": "<iframe width=\"640\" height=\"360\" src=\"http://www.youtube.com/embed/videoseries?list=PLFgcIA8Y9FMD2ecXA8F2_NH2IsbOnOdYG\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
    }
}
 */