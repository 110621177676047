import React from "react"
import { getContentSectionPlaylists as getContentSectionPlaylists } from "../yt_live_player/util/youtube"
import PlaylistItems from "./components/Playlist/PlaylistItems"
import PlaylistCard from "./components/Playlist/PlaylistCard"
import Row from "./components/Row/Row"
import RecentChannelVideos from "./components/Channel/RecentChannelVideos"
import LoaderRow from "./components/Loaders/LoaderRow"
import { SettingsProvider } from "../../context/SettingsContext"

/**
 * This is the entry point for the React app that controls the new YouTube-powered sermon * experience. /app/views/watch/index.html.erb is the view that renders this component.
 * */

function RenderSection({ section, content, locale }) {
  const [playlistIDs, setPlaylistIDs] = React.useState(null)

  const getPlaylistIDs = async (section) => {
    // IF it's a channel_section it's a collection of playlists and we need to fetch the children
    if (section.subClassification == "channel_section") {
      const ids = await getContentSectionPlaylists(section.extId)
      if (ids?.length > 0) {
        return setPlaylistIDs(ids.slice(0, 3))
      }
    }

    // Otherwise, it's a single playlist, assign the original playlistID
    return setPlaylistIDs(section.playlistID)
  }

  React.useEffect(() => {
    getPlaylistIDs(section)
  }, [section])

  return (
    <Row>
      <header className="sermon-index-row-header d-flex align-items-center mb-2 justify-content-between">
        <h2 className="mb-0 h5">{section.title}</h2>
        {section?.viewAll && (
          <a data-turbolinks="false" className="" href={section.viewAll}>
            {content?.index?.view_all}&nbsp;
            <i className="fas fa-chevron-right align-middle"></i>
          </a>
        )}
      </header>
      {playlistIDs && playlistIDs.length > 1 ? (
        <div className="row">
          {/* Displays a single card for each playlist that represents the items in that playlist */}
          {playlistIDs.map((id, i) => (
            <div className="col-md-4" key={i}>
              <PlaylistCard id={id} locale={locale} />
            </div>
          ))}
        </div>
      ) : playlistIDs ? (
        // Displays a row of cards/videos of videos IN that playlist
        <PlaylistItems id={playlistIDs} locale={locale} />
      ) : section?.channelID ? (
        <RecentChannelVideos id={section?.channelID} locale={locale} />
      ) : (
        <LoaderRow />
      )}
    </Row>
  )
}

function App({ content, locale, youTubeIds, ytSections }) {
  const settings = JSON.parse(youTubeIds)

  return (
    <SettingsProvider youtube_ids={settings?.data?.attributes}>
      <div className="container">
        {ytSections
          .map((section) => ({
            id: section.attributes.slug,
            title: section.attributes.name,
            playlistID: [section?.attributes.extId],
            viewAll: section?.attributes.viewAllWeb,
            subClassification: section.attributes.subClassification,
            extId: section.attributes.extId,
          }))
          .map((section, i) => (
            <RenderSection
              section={section}
              key={i}
              locale={locale}
              content={content}
            />
          ))}
      </div>
    </SettingsProvider>
  )
}

export default App
