import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import Map from './Map';

function App({ campuses, locale }) {
  // eslint-disable-next-line no-unused-vars
  const [groups, setGroups] = React.useState([]);
  React.useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const res = axios.get('/data/groups/groups.json');
  }, []);
  // Set abbreviations for campuses
  campuses.map((c) => {
    switch (c.name_en) {
      case 'Southlake Campus':
        c.abbreviation = 'SLK';
        break;
      case 'Dallas Campus':
        c.abbreviation = 'DAL';
        break;
      case 'Frisco Campus':
        c.abbreviation = 'FRS';
        break;
      case 'Grand Prairie Campus':
        c.abbreviation = 'GRP';
        break;
      case 'Jackson Hole Campus':
        c.abbreviation = 'JAC';
        break;
      case 'Justin Campus':
        c.abbreviation = 'JST';
        break;
      case 'NRH Campus':
        c.abbreviation = 'NRH';
        break;
      case 'North Fort Worth':
        c.abbreviation = 'NFW';
        break;
      case 'Plano Campus':
        c.abbreviation = 'PLN';
        break;  
      default:
        c.abbreviation = '';
        break;
    }
    return c;
  });

  return (
    <Router>
      <Map campuses={campuses} groups={groups} locale={locale} />
    </Router>
  );
}

export default App;
