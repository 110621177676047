import React from 'react'
import { getPlaylistItems } from '../../../yt_live_player/util/youtube'
import LoaderRow from '../Loaders/LoaderRow'
import VideoCard from '../Video/VideoCard'

function PlaylistItems({ id, limit = 3, currentVideoID = null, onRelatedItemsDisplayed = null }) {
  const [playlistItems, setPlaylistItems] = React.useState(null)

  const filterPlaylistItems = playlistItemsResponse => {
    return playlistItemsResponse?.items
      .filter((item) => item?.status?.privacyStatus === 'public')
      .filter((item) => {
        // If a currentVideoID is provided, filter out the video that matches that ID
        if (currentVideoID)
          return item?.snippet?.resourceId?.videoId !== currentVideoID
        return true
      });
  }


  React.useEffect(() => {
    const loadPlaylistItems = async () => {
      const response = await getPlaylistItems(id, Math.max(8, limit)) // We're manually setting a limit here because the API currently returns private videos, for some reason.
      if (!response?.error && !response?.errors) {
        let filteredItems = filterPlaylistItems(response);
        setPlaylistItems(filterPlaylistItems(response));
        if (onRelatedItemsDisplayed !== null) {
          onRelatedItemsDisplayed(filteredItems.length);
        }
      }
      return
    }

    loadPlaylistItems()
  }, [id])


  if (!id) return null

  if (!playlistItems) return <LoaderRow />

  // We need to load the data for the provided playlist ID(s)

  // By default this component returns a row of cards/videos that represent a playlist
  return (
    <div className="row">
      {playlistItems
        .slice(0, limit)
        .map((video, i) => (
          <div key={i} className="col-md-4">
            <VideoCard video={video} playlistID={id} locale={locale} />
          </div>
        ))}
    </div>
  )
}

export default PlaylistItems
