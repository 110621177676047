import React from "react"
import YouTube from "react-youtube"
import classNames from "classnames"
import { css } from "@emotion/css"
import YouTubePlayButton from "../../shared/YouTubePlayButton"
import slugify from "slugify"

const opts = {
  height: "auto",
  width: "100%",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    controls: 1,
    mute: 1,
  },
}

function FeaturedSectionCard({ section }) {
  const [showPlayer, setShowPlayer] = React.useState(false)

  React.useEffect(() => {
    setShowPlayer(false)
  }, [section])

  return (
    <div
      className="col-sm-4 recent-sermon"
      data-key={`${slugify(section.title)}-0`}
      data-turbolinks="false"
    >
      {section?.video ? (
        <>
          <span className="live-bug-small">Live</span>
          <div
            className="w-full flex-1 mb-3"
            style={{
              maxWidth: "100%",
              flex: "1",
              aspectRatio: "16 / 9",
              position: "relative",
              display: showPlayer ? "block" : "none",
            }}
          >
            <YouTube
              loading="eager"
              videoId={section?.video?.id}
              opts={opts}
              className={classNames(
                "w-full",
                css`
                  & iframe {
                    aspect-ratio: 16/9;
                    width: 100%;
                  }
                `
              )}
              onEnd={section?.onVideoEnd}
            />
          </div>
          <div
            className={classNames(
              "w-full video-click-to-play flex-col align-items-center justify-content-center p-2 mb-3",
              {
                "d-none": showPlayer,
                "d-flex": !showPlayer,
              }
            )}
            onClick={() => {
              setShowPlayer(true)
              videoPlayer?.target?.playVideo()
            }}
            style={{
              backgroundImage: `url(${
                section?.video?.snippet?.thumbnails?.maxres?.url ||
                section?.video?.snippet?.thumbnails?.standard?.url ||
                section?.video?.snippet?.thumbnails?.high?.url
              })`,
            }}
          >
            <YouTubePlayButton className="play-button" />
          </div>
        </>
      ) : (
        <a href={section.path}>
          <img
            src={`https://cf1.gatewaypeople.com/assets/${section.image}`}
            className="img-fluid"
          />
        </a>
      )}

      <h3 className="h4">{section.title}</h3>

      <div className="details mb-4">
        <p className="date mb-3">
          <em>{section.sub_title}</em>
        </p>
        <p
          className="description"
          dangerouslySetInnerHTML={{
            __html: section.body,
          }}
        />
        <a href={section.path} style={{ color: "#00aae5" }}>
          {section.link_text}{" "}
          <i className="fas fa-chevron-right align-middle"></i>
        </a>
      </div>
    </div>
  )
}

export default FeaturedSectionCard
