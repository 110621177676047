// The circles/clusters
export const clusterLayer = function ({ theme, id, source, background }) {
  const color = theme === 'dark' ? '113, 191, 231' : '0,0,0';
  return {
    id: `${id}-clusters`,
    source,
    type: 'circle',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        background ? background : `rgba(${color}, 0.35)`,
        100,
        background ? background : `rgba(${color}, 0.35)`,
        750,
        background ? background : `rgba(${color}, 0.35)`,
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 40, 750, 50],
    },
  };
};

// The cluster label/number
export const clusterCountLayer = function ({ id, source }) {
  return {
    id: `${id}-cluster-count`,
    source,
    type: 'symbol',
    filter: ['has', 'point_count'],
    paint: {
      'text-color': '#FFFFFF',
    },
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 16,
    },
  };
};

// Individual group marker
export const unclusteredPointLayer = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'groups',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': 'rgba(113, 191, 231, 0.75)',
    'circle-stroke-width': 2,
    'circle-stroke-color': '#fff',
    'circle-radius': 15,
  },
};

// Individual group marker
export const unclusteredPointLayerIcon = function ({ id, source }) {
  return {
    id: `${id}-unclustered-point-icon`,
    source,
    type: 'symbol',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': 'groupIcon',
      'icon-size': 1,
      'icon-offset': [0, -24],
    },
  };
};

// Individual campus marker
export const unclusteredCampusPointLayerIcon = function ({ id, source }) {
  return {
    id: `${id}-unclustered-point-icon`,
    type: 'symbol',
    source,
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': 'campusIcon',
      'icon-size': 1,
      'icon-offset': [0, -24],
    },
  };
};
