import FilterGroups from './FilterGroups';
import FilterOverlay from './FilterOverlay';
import React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import useBreakpoint from '../../../lib/useBreakpoint';

function Filters({
  filterData,
  activeFilters,
  setActiveFilters,
  filteredResults,
  className,
}) {
  const breakpoint = useBreakpoint();
  const [filtersVisible, setFiltersVisible] = React.useState(false);
  // When the mobile filters are visible prevent body scroll
  React.useEffect(() => {
    const body = document.querySelector('body');
    if (filtersVisible) return body.classList.add('no-scroll');
    return body.classList.remove('no-scroll');
  }, [filtersVisible, breakpoint.isMdDown]);

  return (
    <>
      <div className={className}>
        {breakpoint.isMdDown ? ( // Mobile
          <button
            className={classNames(
              'mx-auto d-block uppercase text-serve-gray font-bold py-3',
              css`
                background: transparent;
                border: 0;
                font-size: 16px;
                letter-spacing: 3.2px;

                &:focus {
                  outline: none;
                }
              `
            )}
            onClick={() => setFiltersVisible(true)}
          >
            <i className="fas fa-list-ul"></i> Filter By
          </button>
        ) : (
          // Desktop
          <>
            <h3
              className={classNames(
                'text-serve-dark-blue font-bold',
                css`
                  letter-spacing: 4.4px;
                  font-size: 22px;
                  line-height: 29.3px;
                `
              )}
            >
              FILTER BY
            </h3>
            {activeFilters.length > 0 && (
              <button className={classNames("text-gw-blue underline mt-1", css`
                background: transparent;
                border: 0;
                padding: 0;
                text-decoration: underline;
              `)} onClick={() => setActiveFilters([])}>Reset</button>
            )}
            <FilterGroups
              filters={filterData}
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              setFiltersVisible={setFiltersVisible}
              filteredResults={filteredResults}
            />
          </>
        )}
      </div>
      {filtersVisible &&
        breakpoint.isMdDown && ( // Mobile
          <FilterOverlay
            filters={filterData}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            setFiltersVisible={setFiltersVisible}
            filteredResults={filteredResults}
          />
        )}
    </>
  );
}

export default Filters;
