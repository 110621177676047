import React from 'react'

function Pagination({ response, onPaginate = () => {} }) {
  const handleClick = (e, pageToken) => {
    e.preventDefault()
    onPaginate(pageToken)
    return document.getElementById("top").scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div className="pagination row justify-content-between mb-4">
      {response?.prevPageToken && (
        <>
          <li className="page-item">
            <a
              className="page-link cursor-pointer"
              href="#top"
              onClick={(e) => handleClick(e, response?.prevPageToken)}
            >
              ‹ Prev
            </a>
          </li>
        </>
      )}
      {response?.nextPageToken && (
        <>
          <li className="page-item ml-auto">
            <a
              className="page-link cursor-pointer"
              onClick={(e) => handleClick(e, response?.nextPageToken)}
            >
              Next ›
            </a>
          </li>
        </>
      )}
    </div>
  )
}

export default Pagination
