import React from 'react';

// eslint-disable-next-line no-unused-vars
function ServiceTimes({ locale, campuses, content }) {
  const [selectedCampus, setSelectedCampus] = React.useState(0);
  const campus = campuses?.[selectedCampus] || false;

  if (!campuses) return null;

  return (
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="square-image easter-page__section--service-times__image">
          <img
            src={
              campus?.image?.asset?.url ||
              'https://cf1.gatewaypeople.com/production/fae/image/asset/1/desktop_Southlake_campus.jpg'
            }
            alt={content.title}
          />
        </div>
      </div>

      <div className="col-lg-6 pt-4 pb-6 px-4 px-lg-5">
        <h2 className="text-primary easter-page__section--service-times__header">
          {content.title}
        </h2>
        <div
          className="mb-3"
          dangerouslySetInnerHTML={{ __html: content.body_html }}
        />
        <select
          className="form-control mb-3"
          onChange={(e) => setSelectedCampus(parseInt(e.target.value))}
        >
          {campuses.map((c, j) => (
            <option key={j} value={j}>
              {c.name_en}
            </option>
          ))}
        </select>

        <a
          href={campus.directions_url}
          rel="noreferrer"
          target="_blank"
          dangerouslySetInnerHTML={{ __html: campus.address }}
        />
        <div dangerouslySetInnerHTML={{ __html: content[campus.slug] }} />
      </div>
    </div>
  );
}

export default ServiceTimes;
