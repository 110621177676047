import React from 'react'
import { getChannelVideos } from '../../../yt_live_player/util/youtube'
import LoaderRow from '../Loaders/LoaderRow'
import VideoCard from '../Video/VideoCard'

function RecentChannelVideos({ id, limit = 3, locale = 'en' }) {
  const [recentVideos, setRecentVideos] = React.useState(null)

  React.useEffect(() => {
    const loadRecentVideos = async () => {
      const response = await getChannelVideos(id, limit)
      if (!response?.error && !response?.errors)
        return setRecentVideos(response)
      return
    }

    loadRecentVideos()
  }, [id])

  if (!recentVideos) return <LoaderRow />

  return (
    <div className="row">
      {recentVideos?.items.map((video, i) => (
        <div key={i} className="col-md-4">
          <VideoCard video={video} playlistID={id} locale={locale} />
        </div>
      ))}
    </div>
  )
}

export default RecentChannelVideos
