import React from 'react';
import { css } from '@emotion/css';

function CheckboxToggle({ name, value, onChange, disabled }) {
  return (
    <div
      className={css`
        --controlWidth: 40px;
        --controlHeight: 20px;
        line-height: 0;
        user-select: none;
        display: inline;

        input[type='checkbox'] {
          height: 0;
          width: 0;
          max-height: 0;
          visibility: hidden;
          display: none;

          &:checked + label {
            background: var(--colors-gw-blue);

            &:after {
              left: 94%;
              transform: translateY(-50%) translateX(-100%);
            }
          }

          &:disabled + label {
            background: var(--colors-gray);
            cursor: not-allowed;
          }
        }

        label {
          cursor: pointer;
          text-indent: -9999px;
          width: var(--controlWidth);
          height: var(--controlHeight);
          background: grey;
          display: block;
          border-radius: var(--controlHeight);
          position: relative;
          margin: 0;
          padding: 0;

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 6%;
            width: calc(var(--controlHeight) * 0.8);
            height: calc(var(--controlHeight) * 0.8);
            background: #fff;
            border-radius: calc(var(--controlHeight) * 0.8);
            transition: 0.3s;
            transform: translateY(-50%);
            will-change: left, transform, width;
          }
        }
      `}
    >
      <input
        name={name}
        checked={value}
        type="checkbox"
        id={`${name}-checkbox`}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={`${name}-checkbox`}>Toggle</label>
    </div>
  );
}

CheckboxToggle.defaultProps = {
  disabled: false
}

export default CheckboxToggle;
