import React, { useState } from "react"

export const NotificationContext = React.createContext({
  notifications: [],
  setNotifications: () => {},
})

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([])

  const state = React.useMemo(
    () => ({ notifications, setNotifications }),
    [notifications, setNotifications]
  )

  return (
    <NotificationContext.Provider value={state}>
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => {
  const context = React.useContext(NotificationContext)
  return { ...context }
}

// export const NotificationsDisplay = () => {
//   const { notifications } = useNotification()
//
//   if (!notifications) return null
//
//   return (
//     <>
//       {notifications
//         .filter((n) => n?.title)
//         .map((notification, index) => (
//           <Notification key={index} content={notification} />
//         ))}
//     </>
//   )
// }
