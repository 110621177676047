import React from "react"

export const ContentContext = React.createContext({
  content: null,
  locale: "en",
  campuses: [],
})

export const ContentProvider = ({ children }) => {
  const [locale, setLocale] = React.useState("en")
  const [content, setContent] = React.useState({})
  const [campuses, setCampuses] = React.useState([])

  const state = React.useMemo(
    () => ({ locale, setLocale, content, setContent, campuses, setCampuses }),
    [locale, setLocale, content, setContent, campuses, setCampuses]
  )

  return (
    <ContentContext.Provider value={state}>{children}</ContentContext.Provider>
  )
}

export const useContent = () => {
  const context = React.useContext(ContentContext)
  return { ...context }
}
