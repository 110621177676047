import slugify from "slugify"
export const youTubeChannelURL = "https://www.youtube.com/gatewaychurchtv"
export const apiBase = "https://web-api.gwprox.net"
export const liveServiceURL = `${apiBase}/liveBroadcasts?part=id,snippet,contentDetails,status`
export const liveServiceURLs = {
  upcoming: liveServiceURL + "&broadcastStatus=upcoming",
  live: liveServiceURL + "&broadcastStatus=active",
  completed: liveServiceURL + "&broadcastStatus=completed",
}

const headers = {
  Authorization: "Bearer " + process.env.YT_API_TOKEN,
}

export const getLatestWeekendService = async (playlistId) => {
  // https://developers.google.com/youtube/v3/docs/playlistItems/list
  const playlistItems = await fetch(
    `${apiBase}/playlistItems?part=contentDetails&part=snippet,status,id,contentDetails&maxResults=5&playlistId=${playlistId}`,
    { headers }
  )
  const jsonItems = await playlistItems.json()

  return (
    jsonItems?.items?.filter(
      (i) => i?.status?.privacyStatus === "public"
    )?.[0] || false
  )
}

export const getLiveServices = async (channelId) => {
  const liveServices = await fetch(
    `${liveServiceURLs.live}&channelId=${channelId}`,
    {
      headers,
    }
  )
  const liveServicesJSON = await liveServices.json()
  liveServicesJSON.items = liveServicesJSON.items.filter(
    (item) => item.status.privacyStatus === "public"
  )
  return liveServicesJSON
}

export const getUpcomingServices = async (channelId) => {
  const upcomingServices = await fetch(
    `${liveServiceURLs.upcoming}&channelId=${channelId}`,
    {
      headers,
    }
  )
  const upcomingServicesJSON = await upcomingServices.json()
  // Filter out private streams
  upcomingServicesJSON.items = upcomingServicesJSON.items.filter(
    (item) =>
      item.status.privacyStatus === "public" &&
      item?.snippet?.scheduledStartTime
  )
  return upcomingServicesJSON
}

export const getCompletedServices = async (playlistId) => {
  const completedServices = await fetch(
    `${apiBase}/playlistItems?part=contentDetails,snippet,status,id,contentDetails&maxResults=100&playlistId=${playlistId}`,
    {
      headers,
    }
  )
  const completedServicesJSON = await completedServices.json()
  return {
    ...completedServicesJSON,
    items: filterPrivateVideos(completedServicesJSON.items),
  }
}

export const getPlaylist = async (id) => {
  const playlist = await fetch(
    `${apiBase}/playlists?part=contentDetails,id,localizations,player,snippet,status&id=${id}`,
    { headers }
  )
  const playlistJSON = await playlist.json()
  // If the playlist is unlisted or pricate don't return it
  if (
    playlistJSON?.items?.[0]?.status?.privacyStatus === "unlisted" ||
    playlistJSON?.items?.[0]?.status?.privacyStatus === "private"
  )
    return null
  return playlistJSON
}

export const getPlaylistItems = async (
  id,
  maxResults = 5,
  pageToken = null
) => {
  const playlist = await fetch(
    `${apiBase}/playlistItems?part=contentDetails,snippet,status,id,contentDetails&maxResults=${maxResults}&playlistId=${id}${
      pageToken ? `&pageToken=${pageToken}` : ""
    }`,
    {
      headers,
    }
  )
  const playlistJSON = await playlist.json()
  return {
    ...playlistJSON,
    items: filterPrivateVideos(playlistJSON.items),
  }
}

export const getVideo = async (id) => {
  const video = await fetch(
    `${apiBase}/videos?part=contentDetails&part=contentDetails,snippet,status,liveStreamingDetails,localizations&id=${id}`,
    { headers }
  )
  const videoJSON = await video.json()
  return videoJSON
}

export const getChannelVideos = async (id, limit = 5) => {
  const videos = await fetch(
    `${apiBase}/search?channelId=${id}&part=snippet&order=date&maxResults=${limit}&type=video`,
    { headers }
  )
  const videosJSON = await videos.json()
  return videosJSON
}

export const getChannelLiveVideos = async (id, limit = 5) => {
  const videos = await fetch(
    `${apiBase}/search?channelId=${id}&part=snippet&order=date&maxResults=${limit}&type=video`,
    { headers }
  )
  const videosJSON = await videos.json()
  return videosJSON
}

/**
 * Takes the video title and removes the " | Gateway Church"
 */
export const formatVideoTitle = (title) => {
  // Split title by " | " and filter out a few phrases
  const phrasesToRemove = [
    "Gateway Church",
    "Gateway Church Live",
    "Gateway Church Online",
  ]
  // Regular expression to match a date in the format "Month Day" such as August 11 or Enero 11 as well as August 11-12
  const datePattern =
    /(\b(?:January|February|March|April|May|June|July|August|September|October|November|December|Enero|Febrero|Marzo|Abril|Mayo|Junio|Julio|Agosto|Septiembre|Octubre|Noviembre|Diciembre)\s+\d{1,2}(?:-\d{1,2})?\b)/g

  return (
    title
      ?.split("|")
      // Trim whitespace from each phrase
      .map((phrase) => phrase.trim())
      // Filter out phrases to remove
      .filter((phrase) => !phrasesToRemove.includes(phrase))
      // Filter out dates
      .filter((phrase) => !datePattern.test(phrase))
      .join(" | ")
  )
  // return (
  //   title
  //     ?.replace(' | Gateway Church', '')
  //     .replace('Gateway Church Live | ', '') || title
  // )
}

/**'
 * This function takes the video description and returns the first line/paragraph.
 */
export const formatVideoDescription = (description) => {
  // Split string by new line and return the first element
  return description?.split("\n")?.[0] || description
}

/**
 * Searches the provided text (usually the video description) for a bible.com url. This is used for linking to the sermon notes.
 */
export const getBibleDotComURL = (reference) => {
  // Search string for a bible.com url and return it
  const regex = /(https?:\/\/bible\.com[^\s]+)/g
  const match = regex.exec(reference)
  if (match) return match[0]
  return false
}

export const createYouTubeURL = ({
  type = "video",
  id,
  slug,
  params = "",
  locale = "en",
}) => {
  return `${
    locale !== "en" ? `/${locale}` : ""
  }/sermons/${type}/${id}--${slugify(slug || "", {
    lower: true,
    trim: true,
    remove: /[*+~.()'"!:@,]/g,
  })}${params}`
}

export const getContentSectionPlaylists = async (contentSectionID) => {
  // Grab the section details
  const section = await fetch(
    `${apiBase}/channelSections?part=contentDetails,snippet&id=${contentSectionID}`,
    { headers }
  )
  const json2 = await section.json()

  return json2?.items?.[0]?.contentDetails?.playlists || []
}

export const filterPrivateVideos = (videos) => {
  return videos?.filter(
    (video) =>
      video.status.privacyStatus !== "private" &&
      video.status.privacyStatus !== "unlisted"
  )
}

export function formatDateFromAPI(apiDateString, locale = "en") {
  if (!apiDateString) return null
  const dateObj = new Date(apiDateString)

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  return new Intl.DateTimeFormat(locale, options).format(dateObj)
}

export const getSpeakerName = (video) => {
  // This isn't ready for production yet, there are too many inconsistencies in the video titles
  return false
  // eslint-disable-next-line no-unreachable
  const speakerName = video?.snippet?.title
    ?.split("|")
    .find((phrase) => phrase.includes("Dr.") || phrase.includes("Pastor"))
  if (speakerName) return speakerName
  return false
}

export const isNightOfWorshipService = (videoTitle) => {
  // Check if the videoTitle contains the string "Night of Worship", case insenstive
  return /night of worship/i.test(videoTitle)
}

export const isShabbatService = (videoTitle) => {
  // Check if the videoTitle contains the string "Night of Worship", case insenstive
  return /shabbat/i.test(videoTitle)
}

export const isPresbyteryService = (videoTitle) => {
  // Check if the videoTitle contains the string "Presbytery", case insenstive
  return /presbytery service/i.test(videoTitle)
}

export const isSecondaryService = (videoTitle) => {
  return (
    isNightOfWorshipService(videoTitle) ||
    isShabbatService(videoTitle) ||
    isPresbyteryService(videoTitle)
  )
}
