import React, { useState, useRef, useEffect } from 'react';
import { FlyToInterpolator } from 'react-map-gl';
import { css } from '@emotion/css';
import classNames from 'classnames';
import Map from '../shared/Map';
import Pins from './Pins';
import Popup from '../shared/Popup';
import Loader from '../shared/Loader';
import GroupDetails from '../shared/Popups/GroupDetails';
import useBreakpoint from '../../lib/useBreakpoint';

const mapAnimationStyle = {
  transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
  transitionDuration: 'auto',
};

const GatheringsMap = ({ gatherings, currentGathering, locale }) => {
  const breakpoint = useBreakpoint();
  const mapRef = useRef();
  const [popupContent, setPopupContent] = useState(null);
  const [viewport, setViewport] = useState({
    longitude: -97.3024215500009,
    latitude: 35.64869695581544,
    zoom: breakpoint.isMdUp ? 4 : 3,
    maxzoom: 14,
    width: '100%',
    height: breakpoint.isMdUp ? '50vw' : '70vw',
    ...mapAnimationStyle,
  });

  useEffect(() => {
    setViewport({
      ...viewport,
      zoom: breakpoint.isMdUp ? 4 : 3,
    });
  }, [breakpoint]);

  useEffect(() => {
    if (!currentGathering || !mapRef?.current) return;

    const gathering = gatherings.find((g) => g.id == currentGathering) || false;

    if (gathering) {
      setViewport({
        ...viewport,
        longitude: gathering.longitude,
        latitude: gathering.latitude,
      });

      setPopupContent(gathering);
      const mapEl = mapRef?.current?.getMap()?._container;
      const header = document.querySelector('header.primary-nav');
      const y =
        mapEl.getBoundingClientRect().top -
        header.getBoundingClientRect().height +
        window.scrollY;
      window.scroll({
        top: y,
        behavior: 'smooth',
      });
    }
  }, [currentGathering, gatherings, mapRef]);

  return (
    <>
      <Map
        viewport={viewport}
        setViewport={setViewport}
        popupContent={popupContent}
        setPopupContent={setPopupContent}
        ref={mapRef}
        maxZoom={13}
        geoLocate={true}
      >
        <Pins
          data={gatherings.filter((g) => g.latitude && g.longitude)}
          onClick={(gathering) => {
            setPopupContent(gathering);
            setViewport({
              ...viewport,
              ...mapAnimationStyle,
              latitude: gathering.latitude,
              longitude: gathering.longitude,
            });
          }}
          viewport={viewport}
          popupVisible={popupContent}
        />

        {popupContent && breakpoint.isMdUp && (
          <Popup
            anchor="top"
            longitude={popupContent.longitude}
            latitude={popupContent.latitude}
            closeOnClick={false}
            onClose={setPopupContent}
          >
            <GroupDetails group={popupContent} locale={locale} />
          </Popup>
        )}
      </Map>

      {popupContent && breakpoint.isMdDown && (
        <div className="p-3 position-relative">
          <button
            className={classNames(
              'mapboxgl-popup-close-button',
              css`
                color: white;
                right: 0;
                top: 1rem;
                font-size: 1.5rem;
                width: auto;
              `
            )}
            type="button"
            onClick={() => setPopupContent(null)}
          >
            ×
          </button>
          <GroupDetails group={popupContent} locale={locale} />
        </div>
      )}

      {gatherings.length < 1 && (
        <div
          className={classNames(
            'position-absolute d-flex align-items-center w-100 h-100',
            css`
              top: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.5);
            `
          )}
        >
          <Loader />
        </div>
      )}
    </>
  );
};

export default GatheringsMap;
