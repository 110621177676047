/**
 * Example usage
 * <HideUntilDate date="2020-10-01T00:00:00" html="<h1>Hidden until 10/1/2020</h1>" />
 *
 * or within ERB
 * react_component("hide_until_date/HideUntilDate", {
 *  date: "Nov 11 2022 12:52:00",
 *  html: "<h1>Hidden until 11/11/2022</h1>"
 * })
 */

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import moment from 'moment';

const dateInFuture = (MomentDate) => {
  return MomentDate? MomentDate.diff() > 0 : false;
};

function HideUntilDate({ id = Math.random(), html, date = '', pollingInterval = 5000 }) {
  const [intervalID, setIntervalID] = useState(0);
  const [hidden, setHidden] = useState(true);
  const hideUntilDate = useMemo(
    () => {
     if (!date || date === '') return null

     return moment.utc(
            new Date(date).toLocaleString('en-US', {
              timeZone: 'America/Chicago',
            }) || now
          )
    },
    [date]
  );

  const checkVisibilityStatus = useCallback(() => {
    if (!hidden) return;
    const isHidden = dateInFuture(hideUntilDate);
    setHidden(isHidden);
  }, [hideUntilDate]);

  // Clear interval once component is visible
  useEffect(() => {
    if (!hidden) clearInterval(intervalID);
  }, [hidden]);

  // When component mounts setup interval to check visibility status
  useEffect(() => {
    if (!hideUntilDate || !hideUntilDate.isValid()) return setHidden(false);

    // Initial check onmount
    checkVisibilityStatus();

    // Setup interval to check visibility status
    const interval = setInterval(checkVisibilityStatus, pollingInterval);
    setIntervalID(interval);

    // cleanup when unmounting
    return () => clearInterval(interval);
  }, []);

  return hidden ? null : <div key={id} id={id} dangerouslySetInnerHTML={{ __html: html }} />;
}

export default HideUntilDate;
