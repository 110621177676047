import React from "react"
import { useContent } from "../context/ContentProvider"
import WeekendService from "./WeekendService"

function Events() {
  const { content, locale } = useContent()
  return (
    <section className="christmas-2023__events py-4 pt-lg-8 pb-lg-6">
      <div className="container">
        <WeekendService
          id="max-lucado"
          service={content?.events?.max_lucado}
          className="mb-6 mb-lg-8"
          youtubeId={locale === "en" ? "vQw4NCpmQvk" : "L0ZC2yQUT5Q"}
        />
        <WeekendService
          id="jentezen-franklin"
          service={content?.events?.jentezen_franklin}
          imageAlignment="left"
          youtubeId={locale === "en" ? "TQaUueZzaMM" : "SR9oTN-3c9k"}
        />
      </div>
    </section>
  )
}

export default Events
