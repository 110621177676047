import classNames from "classnames"
import React from "react"
import useBreakpoint from "../../../../lib/useBreakpoint"
import {
  createYouTubeURL,
  getVideo,
} from "../../../yt_live_player/util/youtube"
import YouTubePlayButton from "../../../shared/YouTubePlayButton"
import { useContent } from "../context/ContentProvider"

function WeekendService({
  service,
  imageAlignment = "right",
  className,
  youtubeId = null,
  ...rest
}) {
  const breakpoint = useBreakpoint()
  const [video, setVideo] = React.useState(null)
  const { locale } = useContent()

  React.useEffect(() => {
    if (!youtubeId) return
    const fetchVideo = async () => {
      const video = await getVideo(youtubeId)
      if (video?.items?.[0]) setVideo(video.items[0])
    }

    fetchVideo()
  }, [youtubeId])

  return (
    <div
      className={classNames("row christmas-2023__weekend-service", className)}
      {...rest}
    >
      <div className="col-lg">
        <h3 className="christmas-2023__weekend-service__title mb-2 text-gold font-chronicle">
          {service?.title_html}
        </h3>
        <div className="christmas-2023__weekend-service__date text-ivory mb-3">
          {service?.date}
        </div>
        <div className="christmas-2023__weekend-service__desc text-ivory">
          {service?.description_html}
        </div>
      </div>
      <div
        className={classNames("col-lg", {
          "order-first pr-lg-4":
            imageAlignment === "left" || !breakpoint.isLgUp,
          "order-last pl-lg-4": breakpoint.isLgUp && imageAlignment !== "left",
        })}
      >
        {video ? (
          <a
            className="d-flex w-full video-click-to-play flex-col align-items-center justify-content-center p-2 christmas-2023__weekend-service__img img-fluid mb-2"
            style={{
              backgroundImage: `url(${service?.image})`,
            }}
            href={createYouTubeURL({
              id: video.id,
              slug: video?.snippet?.title.split("|")[0],
              locale,
            })}
          >
            <YouTubePlayButton className="play-button" />
          </a>
        ) : (
          <img
            src={service?.image}
            alt={service?.title_html}
            className="christmas-2023__weekend-service__img img-fluid w-full"
          />
        )}
      </div>
    </div>
  )
}

export default WeekendService
