import CheckboxToggle from '../shared/CheckboxToggle';
import { Icon } from '../shared/globalMapSettings';
import React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import mediaQueries from '../../lib/mediaQueries';

// eslint-disable-next-line no-unused-vars
function Filters({ className, onChange, locale, mapRef, filters, setFilters }) {
  const updateFilters = (field) => {
    setFilters({
      ...filters,
      [field.target.name]:
        field.target.type === 'checkbox'
          ? Boolean(field.target.checked)
          : field.target.value,
    });
  };

  return (
    <aside
      className={classNames(
        className,
        css`
          user-select: none;
          z-index: 100;
          border-radius: 10px;
          background: white;
          padding: 0.5rem 1rem;
          min-height: 50px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 0.8rem;
          color: #41402;
          box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
          border: 1px solid rgba(0, 0, 0, 0.2);

          ${mediaQueries.lg} {
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
            position: absolute;
          }

          label {
            &:last-child {
              margin-bottom: 0;
            }
          }
        `
      )}
    >
      <div>
        <label className="d-flex align-items-center mb-2">
          <img
            src="/assets/icons/campus-icon.png"
            style={{ maxWidth: '20px' }}
            className="mr-1 mb-0"
          />
          <span className="mr-2" style={{ flex: 1 }}>
            Gateway Campuses
          </span>
          {/* <CheckboxToggle name="showCampuses" value={true} disabled={true} /> */}
        </label>

        <label className="d-flex align-items-center mb-2">
          <Icon
            size={20}
            fill="var(--green)"
            transform={false}
            className="mr-1"
          />
          <span className="mr-2" style={{ flex: 1 }}>
            Administrative Office
          </span>
          {/* <CheckboxToggle
            name="showAdminOffices"
            value={filters.showAdminOffices}
            value={true}
            disabled={true}
          /> */}
        </label>

        <label className="d-flex align-items-center mb-2">
          <img
            src="/assets/icons/group-icon-v2.png"
            style={{ maxWidth: '20px' }}
            className="mr-1 mb-0"
          />
          <span className="mr-2" style={{ flex: 1 }}>
            Gateway Gatherings
          </span>
          <CheckboxToggle
            name="showGatherings"
            value={filters.showGatherings}
            onChange={updateFilters}
          />
        </label>

        <label className="d-flex align-items-center">
          <img
            src="/assets/icons/prison-campus-map-icon-v2.png"
            style={{ maxWidth: '20px' }}
            className="mr-1 mb-0"
          />
          <span className="mr-2" style={{ flex: 1 }}>
            Prison Campuses
          </span>
          <CheckboxToggle
            name="showPrisonCampuses"
            value={filters.showPrisonCampuses}
            onChange={updateFilters}
          />
        </label>
      </div>
    </aside>
  );
}

export default Filters;
