import classNames from 'classnames';
import React from 'react';
import './DropdownSelect.css';

// eslint-disable-next-line no-unused-vars
function DropdownSelect({ locale, items }) {
  const [open, setOpen] = React.useState(false);

  return (
    <div
      title={title}
      onClick={() => setOpen(!open)}
      className={classNames(
        'accordion-reveal bg-white accordion-reveal pr-3',
        {
          'line-clamp-1': !open,
        },
        className
      )}
    >
      {open ? <div dangerouslySetInnerHTML={{ __html: content }} /> : title}
      <i className="accordion-reveal__icon far fa-chevron-down" />
    </div>
  );
}

export default DropdownSelect;
