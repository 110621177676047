import React from "react"
import { useContent } from "../context/ContentProvider"

function Candlelight() {
  const { content } = useContent()

  console.log({ content })
  return (
    <section
      id="candlelight"
      className="christmas-2023__candlelight text-ivory py-4 pt-lg-8 pb-lg-6"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 pr-lg-4 mb-3">
            <img
              src={content?.candlelight?.image}
              alt={content?.candlelight?.title_html}
              className="christmas-2023__candlelight__image img-fluid w-full"
            />
          </div>
          <div className="col-lg-6">
            <h3 className="christmas-2023__candlelight__title font-chronicle text-gold mb-2">
              {content?.candlelight?.title_html}
            </h3>
            <time className="d-block christmas-2023__candlelight__date mb-3">
              {content?.candlelight?.date}
            </time>
            <div
              dangerouslySetInnerHTML={{
                __html: content?.candlelight?.description_html,
              }}
            />
            <hr />
            <h4 className="mb-1">
              {content?.candlelight?.campus_times?.title_html}
            </h4>
            <div
              className="mb-3"
              dangerouslySetInnerHTML={{
                __html: content?.candlelight?.campus_times?.description_html,
              }}
            />
            {content?.candlelight?.campus_times?.times?.map((time, index) => (
              <div key={index} className="mb-3">
                <strong>{time.campus}</strong>
                <div dangerouslySetInnerHTML={{ __html: time.times }} />
              </div>
            ))}

            <hr />
            <h4 className="mb-1">
              {content?.candlelight?.interpretation?.title_html}
            </h4>
            <div
              className="mb-3"
              dangerouslySetInnerHTML={{
                __html: content?.candlelight?.interpretation?.description_html,
              }}
            />
            {content?.candlelight?.livestream?.title_html && (
              <>
                <hr />
                <h4 className="mb-1">
                  {content?.candlelight?.livestream?.title_html}
                </h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: content?.candlelight?.livestream?.description_html,
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Candlelight
