function campusAbbreviations(campuses) {
  campuses.map((c, i) => {
    switch (c.slug) {
      case 'southlake':
        campuses[i].abbreviation = 'SLK';
        break;
      case 'dallas':
        campuses[i].abbreviation = 'DAL';
        break;
      case 'frisco':
        campuses[i].abbreviation = 'FRS';
        break;
      case 'grand-prairie':
        campuses[i].abbreviation = 'GRP';
        break;
      case 'jackson-hole':
        campuses[i].abbreviation = 'JAC';
        break;
      case 'justin':
        campuses[i].abbreviation = 'JST';
        break;
      case 'nrh':
        campuses[i].abbreviation = 'NRH';
        break;
      case 'north-fort-worth':
        campuses[i].abbreviation = 'NFW';
        break;
      case 'plano':
        campuses[i].abbreviation = 'PLN';
        break;
      case 'prosper':
        campuses[i].abbreviation = 'PRP';
        break;
      case 'stl':
        campuses[i].abbreviation = 'STL';
        break;
      default:
        campuses[i].abbreviation = '';
        break;
    }
  });

  return campuses;
}

export default campusAbbreviations;
