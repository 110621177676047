import React from "react"
import { getContentSectionPlaylists } from "../../yt_live_player/util/youtube"
import LoaderRow from "../components/Loaders/LoaderRow"
import PlaylistCard from "../components/Playlist/PlaylistCard"
import helpers from "../../../lib/helpers"

function Series({ locale, channelSection }) {
  const [playlists, setPlaylists] = React.useState(null)
  const channelSectionID = helpers.translateField(
    channelSection,
    "ext_id",
    locale,
    true
  )

  React.useEffect(() => {
    if (!channelSectionID) return

    const fetchPlaylists = async () => {
      const data = await getContentSectionPlaylists(channelSectionID)
      setPlaylists(data)
    }

    fetchPlaylists()
  }, [channelSectionID])

  return (
    <div className="container">
      <header className="my-4" id="top">
        <h1 className="h2">
          {helpers.translateField(channelSection, "name", locale, true)}
        </h1>
      </header>

      {playlists?.length > 0 ? (
        <div className="row">
          {playlists.map((playlist, i) => (
            <PlaylistCard
              id={playlist}
              locale={locale}
              key={i}
              className="col-md-4"
            />
          ))}
        </div>
      ) : (
        <LoaderRow />
      )}
    </div>
  )
}

export default Series
