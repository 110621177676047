import React from 'react'
import LoaderCard from '../Loaders/LoaderCard'
import { getVideo } from '../../../yt_live_player/util/youtube'
import VideoCard from './VideoCard'

// Used to load a video of a given id and display the card once it's loaded.

function VideoLoader({ videoId, playlistID, locale = 'en' }) {
  const [video, setVideo] = React.useState(null)

  React.useEffect(() => {
    const fetchVideo = async () => {
      const response = await getVideo(videoId)
      if (!response?.error && !response?.errors && response?.items?.[0])
        setVideo(response?.items?.[0])
    }

    fetchVideo()
  }, [videoId])

  if (!video) return <LoaderCard />

  return (
    <VideoCard video={video} playlistID={playlistID || null} locale={locale} />
  )
}

export default VideoLoader
