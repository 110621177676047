import * as React from 'react';
import { Marker } from 'react-map-gl';

// Important for perf: the markers never change, avoid rerender when the map viewport changes
function Pin({ data, onClick, size, color }) {
  return (
    <Marker longitude={data.lng} latitude={data.lat}>
      <svg
        height={size}
        viewBox="0 0 384 512"
        style={{
          cursor: 'pointer',
          fill: '#fff',
          stroke: 'none',
          transform: `translate(${-size / 2}px,${-size}px)`,
          boxShadow: `0 0 1rem 0 rgba(0,0,0,0.2)`,
          color: color,
        }}
        onClick={() => onClick(data)}
      >
        <path
          fill="currentColor"
          d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
        ></path>
      </svg>
    </Marker>
  );
}

Pin.defaultProps = {
  size: 30,
  color: 'var(--colors-gw-blue)',
};

export default React.memo(Pin);
