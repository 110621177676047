import React from "react"
import classNames from "classnames"
import { css } from "@emotion/css"
import dateFormat from "dateformat"

// eslint-disable-next-line no-unused-vars
function Card({ opportunity, locale }) {
  const displayDate = React.useMemo(() => {
    const date = opportunity.starts_at
    const format =
      dateFormat(date, "M") > 0
        ? "dddd, mmmm d @ h:MM tt"
        : "dddd, mmmm d @ h tt"
    return dateFormat(date, format)
  }, [opportunity.starts_at])
  const description = React.useMemo(() => {
    if (!opportunity.event || !opportunity?.event?.summary) return ""

    return opportunity?.event?.summary
      ?.toString()
      ?.replace(/&nbsp;/g, " ")
      ?.replace(/&rsquo;/g, "’")
      ?.replace(/&#39;/g, "'")
      ?.replace(/(<([^>]+)>)/gi, "")
  }, [opportunity.event])
  const descriptionTruncated = React.useMemo(() => {
    return (description && description?.length > 80) || false
  }, [description])

  return (
    <div
      className={classNames(
        "text-serve-gray",
        css`
          background-color: rgba(255, 229, 147, 0.2);
          border-radius: 10px;
          padding: 2rem 2rem;
        `
      )}
    >
      <h4
        className={classNames(
          "text-serve-dark-blue font-bold mb-2",
          css`
            font-size: 22px;
            line-height: 29.3px;
          `
        )}
      >
        <a
          href={opportunity?.general_registration || null}
          target="_blank"
          rel="noopener noreferrer"
          className={css`
            color: inherit;
          `}
        >
          {opportunity.event.name}
        </a>
      </h4>

      <div className="mb-2">
        {descriptionTruncated ? (
          <>{description && description?.substring(0, 80)}...</>
        ) : (
          <>{description}</>
        )}
        {opportunity?.event?.registration_url && (
          <a
            className={css`
              background: transparent;
              border: none;
              appearance: none;
              color: var(--colors-gw-blue);
              text-decoration: underline;
            `}
            href={opportunity?.event?.registration_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more
          </a>
        )}
      </div>

      <ul
        className={classNames(
          "m-0 p-0",
          css`
            list-style-type: none;
            font-size: 0.8rem;
          `
        )}
      >
        <li>{displayDate}</li>
        <li>
          {opportunity?.tags?.["Website | Tags"]
            ?.filter((t) => t !== "Serve Week")
            ?.join(", ")}
        </li>
        <li>
          <span
            className={classNames(
              css`
                color: #1f7bbd;
              `
            )}
          >
            {opportunity?.campus?.name_en}
          </span>
        </li>
      </ul>
    </div>
  )
}

export default Card
