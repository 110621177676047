import { useHistory, useLocation } from 'react-router';

import React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';

function FilterGroupItem({
  filter,
  activeFilters,
  setActiveFilters,
  filteredResults,
  isExpanded
}) {
  const history = useHistory();
  const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(isExpanded ? true : false);
  const isCampusFilter = (newFilter) => {
    return newFilter.type === 'campus' && newFilter?.slug
  }
  const isActiveFilter = (f, checkValue = true) => {
    return activeFilters.some((af) => {
      if (checkValue) {
        return af.type === f.type && af.value === f.value;
      }
      return af.type === f.type;
    });
  };

  const toggleFilter = (newFilter) => {
    // Update State
    setActiveFilters((prevState) => {
      const newActiveFilters = [
        ...prevState.filter((af) => af.type !== newFilter.type),
      ];

      if (!isActiveFilter(newFilter)) newActiveFilters.push(newFilter);
      return newActiveFilters;
    });

    // Update URL if it's a campus filter
    if (!isActiveFilter(newFilter) && isCampusFilter(newFilter) && location?.params?.campus !== newFilter?.slug) {
      history.push(`/serveweek/${newFilter.slug}`);
    } else if (isCampusFilter(newFilter) && isActiveFilter(newFilter)) {
      history.push(`/serveweek`);
    }

  };

  const filterStillRelevant = (fil, val) => {
    return filteredResults.some((res) => {
      if (fil?.match) return fil.match(val, res);
    });
  };

  return (
    <div
      className={classNames(
        'py-3 text-serve-dark-blue',
        css`
          user-select: none;

          &:not(:last-child) {
            border-bottom: 1px solid var(--color-serve-blue-gray);
          }
        `
      )}
    >
      <div
        className={classNames(
          'd-flex align-items-center justify-content-between',
          css`
            cursor: pointer;
          `
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3
          className={classNames(
            'font-bold uppercase',
            css`
              letter-spacing: 2.8px;
              line-height: 29.3px;
              font-size: 14px;
            `
          )}
        >
          {filter.title}
        </h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11.666"
          height="16"
          viewBox="0 0 11.666 16"
          className={classNames(css`
            max-width: 8px;
          `)}
        >
          <g id="move-expand-vertical" transform="translate(-3.25 0)">
            <path
              id="Path_931"
              data-name="Path 931"
              d="M3.494,6.406a.835.835,0,0,0,1.179,0L8.965,2.124a.168.168,0,0,1,.236,0l4.293,4.282A.832.832,0,0,0,14.673,5.23l-5-4.987a.835.835,0,0,0-1.179,0l-5,4.987a.83.83,0,0,0,0,1.175Z"
              transform="translate(0 0)"
              fill="#002a54"
            />
            <path
              id="Path_932"
              data-name="Path 932"
              d="M14.673,14.269a.835.835,0,0,0-1.179,0L9.2,18.551a.168.168,0,0,1-.236,0L4.673,14.269a.832.832,0,0,0-1.179,1.175l5,4.987a.835.835,0,0,0,1.179,0l5-4.987a.83.83,0,0,0,0-1.175Z"
              transform="translate(0 -4.675)"
              fill="#002a54"
            />
          </g>
        </svg>
      </div>
      {isOpen && (
        <ul className="list-unstyled">
          {filter.data.map((item, j) => {
            const value = filter?.dataKey ? item[filter.dataKey] : item;
            const newFilter = {
              type: filter.key,
              value,
              slug: item?.slug ? item.slug : null,
            };

            return (
              <li
                key={j}
                className={classNames(
                  'py-1 px-2',
                  {
                    'text-gw-blue': isActiveFilter(newFilter),
                  },
                  css`
                    cursor: pointer;
                    background-color: ${isActiveFilter(newFilter)
                      ? '#F7F7F7'
                      : 'transparent'};
                    transform: translateX(-0.75rem);
                    opacity: ${filterStillRelevant(filter, value) ? 1 : 0.25};
                    pointer-events: ${filterStillRelevant(filter, value)
                      ? 'all'
                      : 'none'};
                  `
                )}
                onClick={() => {
                  toggleFilter(newFilter)
                }}
              >
                {value}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default FilterGroupItem;
