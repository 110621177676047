import React from 'react';

function CampusDropdownFactory({ campuses, output, outputMap = null, hideEmpty = false }) {
  const [selectedCampus, setSelectedCampus] = React.useState('SLK');

  return (
    <div>
      <select
        onChange={(e) => setSelectedCampus(e.target.value)}
        className="form-control mb-3 w-100"
      >
        {campuses.map((c, i) => (
          <option key={i} value={c.campus_abbreviation}>
            {c.name_en}
          </option>
        ))}
      </select>

      {(!hideEmpty || (outputMap && outputMap?.[selectedCampus])) && <div
        dangerouslySetInnerHTML={{
          __html: output.replaceAll('[CAMPUS]', selectedCampus).replaceAll('[CAMPUS_MAP_OUTPUT]', outputMap && outputMap?.[selectedCampus] || null),
        }}
      />}
    </div>
  );
}

export default CampusDropdownFactory;
