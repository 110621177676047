import React from 'react'
import PlaylistGrid from '../components/Playlist/PlaylistGrid'
import { getPlaylist } from '../../yt_live_player/util/youtube'
import { Helmet } from 'react-helmet'
import StructuredDataPublisher from "../../shared/StructuredData/StructuredDataPublisher"
import LoaderRow from "../components/Loaders/LoaderRow"
import Skeleton from "react-loading-skeleton"

function Playlist({ id, locale }) {
  const [playlist, setPlaylist] = React.useState(null)
  const { playlistTitle, playlistDescription, playlistPublishDate } =
    React.useMemo(() => {
      return {
        playlistTitle: playlist?.snippet?.localized?.title,
        playlistDescription: playlist?.snippet?.localized?.description,
        playlistPublishDate: playlist?.snippet?.publishedAt,
      }
    })

  React.useEffect(() => {
    const fetchPlaylist = async () => {
      const response = await getPlaylist(id)
      if (!response?.error && !response?.errors && response?.items?.[0])
        setPlaylist(response?.items?.[0])
    }

    fetchPlaylist()
  }, [id])

  if (!playlist)
    return (
      <div className="container my-4">
        <Skeleton
          height={40}
          className="w-full mb-4"
          style={{
            maxWidth: "500px",
          }}
        />
        <LoaderRow />
      </div>
    )

  return (
    <div
      className="container"
      itemScope
      itemType="https://schema.org/CreativeWorkSeries"
    >
      <Helmet>
        <title>{`${playlistTitle} | Gateway Church`}</title>
        <meta name="gwp_type" content="series" />
        <meta property="data-search-priority" content="50" />
        <meta property="data-search-featured" content="false" />
        <meta name="title" content={playlistTitle} />
        <meta property="og:title" content={playlistTitle} />
        <meta property="og:type" content="video.tv_show" />
        <meta name="twitter:card" content="summary" />
        <meta name="description" content={playlistDescription} />
        <meta property="og:description" content={playlistDescription} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:locale" content={locale} />
        <meta property="og:site_name" content="Gateway Church" />
        <meta
          property="og:image"
          content={playlist?.snippet?.thumbnails?.high?.url}
        />
      </Helmet>
      <StructuredDataPublisher />
      <header className="my-4" id="top">
        <h1 itemProp="name" className="h2">
          {playlistTitle}
        </h1>
        <meta itemProp="datePublished" content={playlistPublishDate} />
        <meta itemProp="url" content={window.location.href} />
        {false && playlistDescription && (
          <p className="lead">{playlistDescription}</p>
        )}
      </header>
      <PlaylistGrid id={id} locale={locale} />
    </div>
  )
}

export default Playlist
