import { FlyToInterpolator } from 'react-map-gl';
// eslint-ignore-file
import React from 'react';
export const defaultIcon = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9C20.1,15.8,20.2,15.8,20.2,15.7z`;

export const Icon = function ({ size, fill, onClick, transform, className }) {
  return (
    <svg
      height={size}
      viewBox="0 0 24 24"
      style={{
        cursor: 'pointer',
        fill,
        stroke: 'none',
        transform: `${transform === false ? 'none' : 'translate(-50%, -100%)'}`,
      }}
      onClick={onClick}
      className={className}
    >
      <path d={defaultIcon} />
    </svg>
  );
};

export const mapAnimationStyle = {
  transitionInterpolator: new FlyToInterpolator({ speed: 1 }),
  transitionDuration: 'auto',
};

export const loadIcons = function (event) {
  const map = event.target;
  map.loadImage('/assets/icons/group-icon-v2.png', (error, image) => {
    if (error) throw error;
    return map.addImage('groupIcon', image);
  });
  map.loadImage('/assets/icons/campus-map-icon.png', (error, image) => {
    if (error) throw error;
    return map.addImage('campusIcon', image);
  });
  map.loadImage('/assets/icons/prison-campus-map-icon-v2.png', (error, image) => {
    if (error) throw error;
    return map.addImage('prisonCampusIcon', image);
  });
};

export const scrollMapIntoView = function (mapRef) {
  const mapEl = mapRef?.current?.getMap()?._container;
  const header = document.querySelector('header.primary-nav');
  const y =
    mapEl.getBoundingClientRect().top -
    header.getBoundingClientRect().height +
    window.scrollY;
  window.scroll({
    top: y,
    behavior: 'smooth',
  });
};

// The circles/clusters
export const clusterLayer = function ({ theme, id, source, background }) {
  const color = theme === 'dark' ? '113, 191, 231' : '0,0,0';
  return {
    id: `${id}-clusters`,
    source,
    type: 'circle',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        background ? background : `rgba(${color}, 0.35)`,
        100,
        background ? background : `rgba(${color}, 0.35)`,
        750,
        background ? background : `rgba(${color}, 0.35)`,
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 40, 750, 50],
    },
  };
};

// The cluster label/number
export const clusterCountLayer = function ({ id, source }) {
  return {
    id: `${id}-cluster-count`,
    source,
    type: 'symbol',
    filter: ['has', 'point_count'],
    paint: {
      'text-color': '#FFFFFF',
    },
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 16,
    },
  };
};

// Individual label
export const layerLabel = function ({ id, source }) {
  return {
    id: `${id}-label`,
    type: 'symbol',
    source,
    filter: ['!', ['has', 'point_count']],
    paint: {
      'text-color': '#000000',
    },
    layout: {
      'text-field': '{title}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
      'text-allow-overlap': true,
      'text-anchor': 'top',
      'text-offset': [0, 0.5],
    },
  };
};

// Individual group marker
export const unclusteredPointLayerIcon = function ({
  id,
  source,
  icon = 'groupIcon',
  iconSize = 1,
}) {
  return {
    id: `${id}-unclustered-point-icon`,
    source,
    type: 'symbol',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': icon,
      'icon-size': iconSize,
      'icon-offset': [0, -24],
    },
  };
};

// Individual campus marker
export const unclusteredCampusPointLayerIcon = function ({ id, source }) {
  return {
    id: `${id}-unclustered-point-icon`,
    type: 'symbol',
    source,
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': 'campusIcon',
      'icon-size': 1,
      'icon-offset': [0, -24],
      'icon-allow-overlap': true,
    },
  };
};

/* jshint ignore:end */