import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import React, { useCallback, useRef, useState } from 'react';
import Geocoder from 'react-map-gl-geocoder';
import classNames from 'classnames';
import { css } from '@emotion/css';
import helpers from '../../lib/helpers';

function Filters({
  className,
  campuses,
  groupTypes,
  onChange,
  locale,
  mapRef,
  setViewport,
  token,
}) {
  const [open, setOpen] = useState(false);
  const geocoderContainerRef = useRef();
  const handleViewportChange = useCallback(
    (newViewport) => setViewport(newViewport),
    []
  );
  const handleGeocoderViewportChange = useCallback(
    (newViewport) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 };

      return handleViewportChange({
        ...newViewport,
        ...geocoderDefaultOverrides,
      });
    },
    [handleViewportChange]
  );

  return (
    <>
      <aside
        className={classNames(
          className,
          'd-flex flex-column position-absolute',
          css`
            --textColor: #c5c5c5;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.9);
            width: 400px;
            padding: 2rem;
            color: var(--textColor);
            transform: translateX(${open ? '0' : '100%'});
            transition: transform 0.5s ease;
            will-change: transform;

            h3 {
              color: #797979;
              font-size: 1rem;
              letter-spacing: 0.1rem;
              text-transform: uppercase;
            }

            .form-group {
              margin-bottom: 1.5rem;

              label {
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 0.1em;
                font-size: 0.8rem;
              }
            }

            .form-control {
              background: transparent;
              color: inherit;
              border-color: inherit;
            }

            .mapboxgl-ctrl-geocoder--icon {
              left: 0;
              fill: var(--textColor);
            }

            .mapboxgl-ctrl-geocoder {
              background: transparent;
              max-width: none;
              width: 100%;
            }

            .mapboxgl-ctrl-geocoder--input {
              height: 38px;
              font-family: inherit;
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.5;
              color: inherit;
              padding-left: 30px;
              width: 100%;
              border-bottom: 1px solid var(--textColor);

              &:focus {
                outline: none;
              }
            }

            .suggestions {
            }
          `
        )}
      >
        <h3 className="mb-3 uppercase">Filters</h3>
        <div className="form-group" ref={geocoderContainerRef}></div>
        <Geocoder
          containerRef={geocoderContainerRef}
          mapRef={mapRef}
          countries="us"
          onViewportChange={handleGeocoderViewportChange}
          mapboxApiAccessToken={token}
          marker={false}
        />
        <div className="form-group">
          <label>Filter By Campus</label>
          <select
            onChange={(e) =>
              onChange(
                e.target.value !== 'false'
                  ? { key: 'campus', value: e.target.value }
                  : false
              )
            }
            className="form-control"
          >
            <option value={false}>-- Select Campus --</option>
            {campuses.map((c, i) => (
              <option key={i} value={c.abbreviation}>
                {helpers.translateField(c, 'name', locale)}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mb-0">
          <label>Filter By Type</label>
          <select
            onChange={(e) =>
              onChange(
                e.target.value !== 'false'
                  ? { key: 'type_id', value: e.target.value }
                  : false
              )
            }
            className="form-control"
          >
            <option value={false}>-- Select Type --</option>
            {groupTypes
              .sort((a, b) => {
                var x = a.name;
                var y = b.name;
                return x < y ? -1 : x > y ? 1 : 0;
              })
              .map((c, i) => (
                <option key={i} value={c.id}>{c.name}</option>
              ))}
          </select>
        </div>
      </aside>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className={classNames(
          'position-absolute',
          css`
            background: transparent;
            border: 0;
            padding: 0;
            right: 2rem;
            top: 2rem;
            color: white;

            &:focus {
              outline: none;
            }
          `
        )}
      >
        {open ? (
          <i className="fal fa-times fa-2x" />
        ) : (
          <span
            className={css`
              display: inline-flex;
              align-items: center;
              justify-content: center;
              background-color: rgba(0, 0, 0, 0.8);
              border-radius: 50%;
              width: 50px;
              height: 50px;

              i {
                font-size: 1.5em;
              }
            `}
          >
            <i className="fas fa-filter" />
          </span>
        )}
      </button>
    </>
  );
}

export default Filters;
