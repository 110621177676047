import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function SermonLoading() {
  return (
    <div
      className="sermons"
      style={{
        flex: '1',
        minHeight: '100%',
        height: '100%',
      }}
    >
      <header className="featured-image gwp-flex-container mb-6">
        <Skeleton className="w-full h-auto" style={{ aspectRatio: '16/9' }} />
      </header>

      <section>
        <div className="container">
          <div className="row px-md-5">
            <div className="col-md-8 offset-md-2">
              <Skeleton height={44} className="w-full mb-2" />
              <Skeleton
                height={44}
                className="w-full mb-2"
                style={{ maxWidth: '60%' }}
              />
              <Skeleton height={14} width={150} className="mb-4" />
              <div className="speaker"></div>
              <Skeleton height={12} className="w-full" count={3} />
              <Skeleton width={150} height={16} className="mt-2" />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default SermonLoading
